import React, { Fragment } from "react";
import { compose, lifecycle } from "recompose";
import styled from "styled-components";
import { Form, Field, withFormik, FieldArray } from "formik";

import { Img } from "react-image-loading";
import AddIcon from "@material-ui/icons/Add";
import { ButtonConfirm } from "../sponsorartikelen/CheckoutForm";
import Icon from "@material-ui/core/Icon";
import { CustomFab, CustomSmallFab } from "./NewsItemsListCms";
import CloseIcon from "@material-ui/icons/Close";
import CustomEditor from "./CustomEditor";
import {
  ContentState,
  convertFromRaw,
  convertToRaw,
  EditorState,
} from "draft-js";

const DeStichtingEditForm = (props) => {
  const onUploadPicture = (setFieldValue, index) => {
    /*eslint-disable */
    cloudinary.openUploadWidget(
      {
        cloud_name: "djhc9jhiq",
        upload_preset: "FRONT_HEADER",
        tags: ["maya"],
      },
      /*eslint-enable */
      function(error, result) {
        if (error) {
          console.log(error);
        } else {
          if (result.info.url) {
          return setFieldValue(`members.${index}.picture`, result.info.url);
          }
        }
      }
    );
  };
  const clearValue = (setFieldValue, fieldValue) =>
    setFieldValue(fieldValue, "");
  const handleToggle = (fieldValue) => {
    props.setFieldValue(fieldValue, !props.values.hasSizes);
  };
  console.log(props);
  return (
    <Fragment>
      <CreateNewsForm>
        <Label>Titel</Label>
        <FormInput type="text" name="title" />
        <Label style={{ marginBottom: "10px" }}>Leden</Label>
        <FieldArray
          name="members"
          render={(arrayHelpers) => (
            <DescriptionsList>
              {props.values.members.map((member, index) => (
                <>
                  <SwitchItem>
                    <Label style={{ marginLeft: "5px", width: "25%" }}>
                      Naam
                    </Label>
                    <Label style={{ marginLeft: "5px", width: "25%" }}>
                      functie
                    </Label>
                    <Label style={{ marginLeft: "5px", width: "50%" }}>
                      Description
                    </Label>
                  </SwitchItem>
                  <DescriptionItem>
                    <FormInput
                      style={{ marginLeft: "5px", width: "25%" }}
                      name={`members.${index}.name`}
                    />
                    <FormInput
                      style={{ marginLeft: "5px", width: "25%" }}
                      name={`members.${index}.function`}
                    />
                    <FormText
                      style={{ marginLeft: "5px", width: "30%" }}
                      name={`members.${index}.description`}
                      component="textarea"
                    />
                    {member && member.picture && member.picture.length > 0 ? (
                      <ImageUpload>
                        <ImageWrapper>
                          <CustomSmallFab
                            onClick={() =>
                              props.setFieldValue(
                                `members.${index}.picture`,
                                ""
                              )
                            }
                          >
                            <CloseIcon />
                          </CustomSmallFab>
                          <ImagePreview src={member.picture}></ImagePreview>
                        </ImageWrapper>
                      </ImageUpload>
                    ) : (
                      <ImageUpload>
                        <CustomFab
                          color="primary"
                          aria-label="add"
                          onClick={() =>
                            onUploadPicture(props.setFieldValue, index)
                          }
                        >
                          <AddIcon />
                        </CustomFab>
                      </ImageUpload>
                    )}
                    <Icon
                      onClick={() => arrayHelpers.remove(index)}
                      style={{ marginLeft: "40px" }}
                    >
                      clear
                    </Icon>
                  </DescriptionItem>
                </>
              ))}
              <DescriptionItem>
                <AddSquareButton onClick={() => arrayHelpers.push("")}>
                  Voeg een teamlid toe
                </AddSquareButton>
              </DescriptionItem>
            </DescriptionsList>
          )}
        />
        <Label style={{ marginBottom: "10px" }}>intro text</Label>
        <FormArea
          type="text"
          style={{ width: "100%" }}
          name={`introText`}
          component="textarea"
        />
        <ButtonWrapper>
          {!props.values.published && (
            <ConfirmButton
              onClick={() => props.handleSubmit()}
              disabled={props.isSubmitting}
            >
              Opslaan
            </ConfirmButton>
          )}
          <ConfirmButton
            onClick={(e) => {
              props.setFieldValue("published", true, false);
              props.handleSubmit(e);
            }}
            disabled={props.isSubmitting}
          >
            Opslaan en publiceren
          </ConfirmButton>
        </ButtonWrapper>
      </CreateNewsForm>
    </Fragment>
  );
};

const CreateNewsForm = styled(Form)`
  display: flex;
  width: 100%;
  padding: 25px;
  flex-direction: column;
  overflow: "scroll";
  background-color: white;
`;

const ConfirmButton = styled(ButtonConfirm)`
  float: right;
  margin-left: 10px;
`;

const AddSquareButton = styled(ButtonConfirm)`
  background-color: #007ec9;
  padding: 0;
`;

const Label = styled.p`
  font-size: 16px;
  font-family: "Arial";
  margin-bottom: 5px;
`;

const FormArea = styled(Field)`
  height: 70px;
  font-size: 18px;
  border-radius: 5px;
  padding-left: 5px;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid grey;
  margin-bottom: 20px;
`;

const FormInput = styled(Field)`
  width: 100%;
  height: 30px;
  font-size: 18px;
  border-radius: 5px;
  padding-left: 5px;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid grey;
  margin-bottom: 10px;
`;

const FormText = styled(Field)`
  width: 100%;
  height: 200px;
  font-size: 18px;
  border-radius: 5px;
  padding-left: 5px;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid grey;
  margin-bottom: 10px;
`;

const DescriptionsList = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
`;

const DescriptionItem = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  font-size: 18px;
`;

export const SwitchItem = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 10px;
  font-size: 18px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 0;
    margin-bottom: 20px
    justify-content: flex-end;
    align-items: right;
`;

const ColorPreview = styled.div`
  display: flex;
  width: 50px;
  height: 30px;
  margin-left: 5px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: ${(props) => props.backgroundColor};
  padding: 0;
`;
const ImageUpload = styled.div`
  display: flex;
  margin-left: 50px;
  min-width: 100px;
  max-width: 100px;
  min-height: 100px;
  max-height: 100px;
  margin-top: -20px;
  border-radius: 50px;
  border: 1px solid black;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ImagePreview = styled(Img)`
  max-width: 100px;
  min-width: 100px;
  min-height: 100px;
  max-height: 100px;
  border-radius: 50px;
  border: 1px solid black;
`;

const ImageWrapper = styled.div`
  max-width: 100%;
  position: relative;
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
`;

export default compose(
  withFormik({
    mapPropsToValues: (props) => {
      if (props.isEdit) {
        return {
          title: props.data.title,
          introText: props.data.introText ? props.data.introText : "",
          members: props.data.members ? props.data.members : [],
        };
      } else {
        return {
          title: "",
          editorState: new EditorState.createEmpty(),
          headerPhoto: "",
          experienceText: "",
          experienceQuotes: [],
          costsText: "",
          costs: [],
        };
      }
    },
    handleSubmit: (values, { props }) => {
      const submitValues = {
        title: values.title,
        introText: values.introText,
        members: values.members,
        isEnglish: props.isEnglish,
      };
      props.submitEndpoint.perform({ id: props.id }, submitValues);
    },
    displayName: "MyForm",
  })
)(DeStichtingEditForm);
