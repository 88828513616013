import { USER_SIGNED_IN, LOGIN_FAILED } from "../actions/admin/sign-in";
import { USER_SIGNED_OUT } from "../actions/admin/sign-out";

const CURRENT_USER_KEY = "currentUserEvaluations";

const currentUserFromLocalStorage = JSON.parse(
  window.localStorage.getItem(CURRENT_USER_KEY) || "null"
);

export default (
  state = currentUserFromLocalStorage,
  { type, payload } = {}
) => {
  switch (type) {
    case USER_SIGNED_IN:
      const currentUser = { ...payload };
      window.localStorage.setItem(
        CURRENT_USER_KEY,
        JSON.stringify(currentUser)
      );
      return currentUser;

    case USER_SIGNED_OUT:
      window.localStorage.removeItem(CURRENT_USER_KEY);
      return null;
    case LOGIN_FAILED:
      return { error: true };
    default:
      return state;
  }
};
