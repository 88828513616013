
import {createReducer} from "redux-updaters";

const initialState = {
    orderId: '',
    paymentId: '',
    subscriptionId: ''
};


const payment = createReducer(initialState, 'payment');

export default payment
