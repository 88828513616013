import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { compose, lifecycle } from "recompose";
import styled from "styled-components";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useDispatch } from "react-redux";
import logOut from "../actions/admin/sign-out";
import ProjectIcon from "../img/improvement.svg";
import NewsIcon from "../img/newspaper.svg";
import ShopIcon from "../img/shopping-cart.svg";
import HomeIcon from "@material-ui/icons/Home";
import GroupIcon from "@material-ui/icons/Group";
import AboutIcon from "@material-ui/icons/PersonPin";
import BartChartIcon from "@material-ui/icons/BarChart";
import logo from "../img/MayaLogo.svg";
import { NavLink, Route } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import NewsItemsListCms from "./NewsItemsListCms";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useActions } from "react-api-data";
import ProjectListCms from "./ProjectListCms";
import ArticleListCms from "./ArticleList";
import NewsItemCreate from "./NewsItemCreate";
import NewsItemEdit from "./NewsItemEdit";
import ArticleCreate from "./ArticleCreate";
import ArticleEdit from "./ArticleEdit";
import ProjectCreate from "./ProjectCreate";
import ProjectEdit from "./ProjectEdit";
import MayaHomeEdit from "./MayaHomeEdit";
import AboutUs from "./AboutUs";
import DeStichtingEdit from "./DeStichtingEdit";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginTop: "65px",
    top: "65px",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "white",
    boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.25)",
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginTop: 65,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.25)",
    marginTop: 65,
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.25)",
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    marginTop: 65,
  },
  listItem: {
    height: "40px",
    marginBottom: "10px",
    padding: "26px 26px",
  },
  toolbar: {},
  listItemIcon: {
    width: "20px",
    height: "20px",
  },
  listText: {
    fontSize: "18px",
    lineHeight: "5px",
  },
  bigAvatar: {
    marginTop: 10,
    width: 40,
    height: 40,
    marginBottom: 10,
    marginRight: 10,
  },
}));

const CmsContainer = (props) => {
  const { loggedIn } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const actions = useActions();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  if (loggedIn) {
    return (
      <Container>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <NavLink to="/">
              <img src={logo} alt="" weign="40" height="40" />
            </NavLink>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open}
        >
          <Toolbar>
            <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
              {!open ? (
                <ChevronRightIcon fontSize={"large"} />
              ) : (
                <ChevronLeftIcon fontSize={"large"} />
              )}
            </IconButton>
          </Toolbar>
          <Divider />
          <Toolbar className={classes.toolbar}>
            <Avatar
              alt="Maya user"
              src={props.currentUser.picture}
              className={classes.bigAvatar}
            />
            <ListItemText
              primary={props.currentUser.email}
              className={classes.listText}
              disableTypography
            />
          </Toolbar>
          <Divider />
          <List>
            <ListItem
              button
              key={"Projecten"}
              className={classes.listItem}
              onClick={() => props.history.push("/cms/container/projects")}
            >
              <ListItemIcon className={classes.icon}>
                <ListIcon src={ProjectIcon} />{" "}
              </ListItemIcon>
              <ListItemText
                primary={"Projecten"}
                className={classes.listText}
                disableTypography
              />
            </ListItem>
            <ListItem
              button
              key={"Nieuws"}
              className={classes.listItem}
              onClick={() => props.history.push("/cms/container/newsitems")}
            >
              <ListItemIcon className={classes.icon}>
                <ListIcon src={NewsIcon} />{" "}
              </ListItemIcon>
              <ListItemText
                primary={"Nieuws"}
                className={classes.listText}
                disableTypography
              />
            </ListItem>
            <ListItem
              button
              key={"Sponsor Artikelen"}
              className={classes.listItem}
              onClick={() => props.history.push("/cms/container/artikelen")}
            >
              <ListItemIcon className={classes.icon}>
                <ListIcon src={ShopIcon} />{" "}
              </ListItemIcon>
              <ListItemText
                primary={"Sponsorartikelen"}
                className={classes.listText}
                disableTypography
              />
            </ListItem>
            <ListItem
              button
              key={"visie"}
              className={classes.listItem}
              onClick={() => props.history.push("/cms/container/aboutUs")}
            >
              <ListItemIcon className={classes.icon}>
                <AboutIcon fontSize={"large"} />{" "}
              </ListItemIcon>
              <ListItemText
                primary={"visie"}
                className={classes.listText}
                disableTypography
              />
            </ListItem>
            <ListItem
              button
              key={"De stichting"}
              className={classes.listItem}
              onClick={() => props.history.push("/cms/container/deStichting")}
            >
              <ListItemIcon className={classes.icon}>
                <GroupIcon fontSize={"large"} />{" "}
              </ListItemIcon>
              <ListItemText
                primary={"De Stichting"}
                className={classes.listText}
                disableTypography
              />
            </ListItem>
            <ListItem
              button
              key={"Maya Home"}
              className={classes.listItem}
              onClick={() => props.history.push("/cms/container/home")}
            >
              <ListItemIcon className={classes.icon}>
                <HomeIcon fontSize={"large"} />{" "}
              </ListItemIcon>
              <ListItemText
                primary={"Maya Home"}
                className={classes.listText}
                disableTypography
              />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem
              onClick={() => actions.perform("publish", {}, {})}
              button
              key={"publish"}
              className={classes.listItem}
            >
              <ListItemText
                primary={"Publish"}
                className={classes.listText}
                disableTypography
              />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem
              onClick={() => {
                dispatch(logOut());
                props.history.push("/");
              }}
              button
              key={"logout"}
              className={classes.listItem}
            >
              <ListItemText
                primary={"logout"}
                className={classes.listText}
                disableTypography
              />
            </ListItem>
          </List>
          <Divider />
        </Drawer>
        <Route
          exact
          path="/cms/container/newsitems"
          component={NewsItemsListCms}
        />
        <Route
          exact
          path="/cms/container/newsitems/create"
          component={NewsItemCreate}
        />
        <Route
          exact
          path="/cms/container/newsitems/edit"
          component={NewsItemEdit}
        />
        <Route
          exact
          path="/cms/container/projects"
          component={ProjectListCms}
        />
        <Route
          exact
          path="/cms/container/artikelen"
          component={ArticleListCms}
        />
        <Route
          exact
          path="/cms/container/artikelen/create"
          component={ArticleCreate}
        />
        <Route
          exact
          path="/cms/container/artikelen/edit"
          component={ArticleEdit}
        />
        <Route
          exact
          path="/cms/container/projects/create"
          component={ProjectCreate}
        />
        <Route
          exact
          path="/cms/container/projects/edit"
          component={ProjectEdit}
        />
        <Route exact path="/cms/container/home" component={MayaHomeEdit} />
        <Route exact path="/cms/container/aboutUs" component={AboutUs} />
        <Route
          exact
          path="/cms/container/deStichting"
          component={DeStichtingEdit}
        />
      </Container>
    );
  } else {
    return null;
  }
};

const Container = styled.div`
  display: flex;
  max-width: 100vw;
  min-height: 100vh;
  justify-content: flex-start;
  flex-direction: row;
  background-color: #fef4ea;
`;

const ListIcon = styled.img`
  width: 22px;
  height: 22px;
`;

const mapStateToProps = (state) => ({
  loggedIn: !!state.currentUser,
  currentUser: state.currentUser,
});
export default compose(
  connect(mapStateToProps, null),
  lifecycle({
    componentDidMount() {
      if (!this.props.loggedIn) {
        this.props.history.push("/cms");
      }
    },
  })
)(CmsContainer);
