import React, { useState, Fragment } from "react";
// import ProjectsContainer from "./projects/ProjectsContainer";
// import newsItemContainer from "./news/newsItemContainer";
// import ProjectPage from "./projects/ProjectPage";
import newsItemPage from "./news/NewsPage";
import SignIn from "./admin/sign-in";
import Home from "./containers/Home";
// import OverOns from "./containers/Overons";
// import DonationPage from "./donation/DonationPage";
import CmsContainer from "./admin/CmsContainer";
import { Route, Switch, Redirect } from "react-router-dom";
// import OrderPage from "./components/OrderPage";
// import PaymentPage from "./components/PaymentPage";
// import SubscriptionPage from "./components/SubscriptionPage";
// import StopSubscriptionPage from "./components/StopSubscriptionPage";
// import SponsorArtikelDetailPage from "./sponsorartikelen/SponsorArtikelDetailPage";
// import NewsItemsListCms from "./admin/NewsItemsListCms";
// import MayaHome from "./mayaHome/MayaHome";
// import JoinMayaHome from "./mayaHome/JoinMayaHome";
// import MollieHomeThankYou from "./mayaHome/MollieHomeThankYou";

const base = "/:locale(en|nl)?";

const Routes = ({ locale }) => {
  return (
    <Fragment>
      <Route exact path="/" component={SignIn} />
      <Route path="/cms" component={CmsContainer} />
    </Fragment>
  );
};

export default Routes;
