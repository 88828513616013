import { compose } from "redux";
import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import Appear from "./Appear";
import { withApiData } from "react-api-data";
import CustomGridLoader from "../Animations/CustomGridLoader";
import { injectIntl } from "react-intl";

const styles = {
  slickActive: {
    width: "17.1px",
    height: "17.1px",
    backgroundColor: "#333333",
    borderRadius: "50%",
    zIndex: 3,
    marginLeft: "10px",
  },
  slickNon: {
    width: "17.1px",
    height: "17.1px",
    backgroundColor: "#ffffff",
    color: "#ffffff",
    borderRadius: "50%",
    zIndex: 3,
    marginLeft: "10px",
    fontSize: "0px",
    opacity: 1,
  },
  slickDots: {
    bottom: "50px",
    fontSize: "0px",
    width: "95%",
  },
};

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1080,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        speed: 500,
        dots: true,
      },
    },
  ],
};

const enhance = compose(
  injectIntl,
  withApiData(
    {
      deStichting: "getDeStichting",
    },
    (ownProps) => ({
      deStichting: {
        isEnglish: ownProps.intl.locale === "en",
      },
    })
  )
);

const DeStichting = (props) => (
  <Container>
    {props.deStichting.request.networkStatus === "success" && (
      <>
        <Title>{props.deStichting.data.title}</Title>
        <Text>{props.deStichting.data.introText}</Text>
        <SliderContainer>
          <Slider {...settings} style={{ maxHeight: "300px", zIndex: 2 }}>
            {props.deStichting.data.members &&
              props.deStichting.data.members.map((item, index) => (
                <SliderItem key={index}>
                  <Picture src={item.picture} />
                  <Name>{item.name}</Name>
                  <TeamFunction>{item.function}</TeamFunction>
                </SliderItem>
              ))}
          </Slider>
        </SliderContainer>
      </>
    )}
    <CustomGridLoader
      sizeUnit={"px"}
      size={50}
      margin={"15px"}
      color={"#007ec9"}
      secondColor={"#f71635"}
      loading={props.deStichting.request.networkStatus === "loading"}
    />
  </Container>
);
const Container = styled(Appear)`
  width: 100%;
  height: 728px;
  background-color: #e6f2fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Text = styled.div`
  width: 1100px;
  height: 184px;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0px;
  white-space: pre-line;
  color: #333333;
  margin-top: 16px;
  margin-bottom: 16px;
  @media only screen and (min-width: 481px) and (max-width: 1080px) {
    width: 600px;
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 320px;
  }
`;

const Title = styled.div`
  width: 1100px;
  height: 44px;
  font-family: "Nunito Sans";
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #333333;
  @media only screen and (min-width: 481px) and (max-width: 1080px) {
    width: 600px;
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 320px;
  }
`;
const SliderContainer = styled.div`
  width: 1150px;
  height: 400px;
  background-color: #e6f2fa;
  @media only screen and (min-width: 481px) and (max-width: 1080px) {
    width: 700px;
    margin-top: 180px;
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 330px;
    margin-top: 100px;
    width: 320px;
  }
`;

const SliderItem = styled.div`
  max-width: 320px;
  height: 244px;
  margin-left: 20px;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 330px;
    margin-top: 20px;
    margin-left: 0;
  }
`;

const Picture = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
`;

const Name = styled.p`
  width: 200px;
  height: 25px;
  text-align: center;
  margin-top: 10px;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #333333;
`;

const TeamFunction = styled.p`
  width: 200px;
  height: 22px;
  font-family: Avenir-Roman;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #666666;
  text-align: center;
`;
export default enhance(DeStichting);
