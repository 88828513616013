import API from "../../api/client";
import { push } from "react-router-redux";

export const USER_SIGNED_IN = "USER_SIGNED_IN";
export const LOGIN_FAILED = "LOGIN_FAILED";

const api = new API();

export default (user) => {
  return (dispatch) => {
    api
      .post("/sessions", { ...user })
      .then((res) => {
        api.storeToken(res.body.token);

        api
          .get("/users/me")
          .then((res) => {
            dispatch({
              type: USER_SIGNED_IN,
              payload: res.body,
            });

            dispatch(push("/cms/container"));
          })
          .catch((err) => {
            dispatch({
              type: LOGIN_FAILED,
              payload: { error: true },
            });
          });
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_FAILED,
          payload: { error: true },
        });
      });
  };
};
