import React from "react";
import PartnerBannerMap from "../fixtures/Sponsors";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const PartnerBanner = () => (
  <Container>
    <Title>
      <FormattedMessage id="ourPartners" defaultMessage="Onze partners" />
    </Title>
    <PartnerContainer>
      {PartnerBannerMap.map((item, index) => (
        <SponsorItem key={index}>
          <Picture src={require(`../img/${item.name}.png`)} />
        </SponsorItem>
      ))}
    </PartnerContainer>
  </Container>
);

const Container = styled.div`
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const Title = styled.div`
  width: 1100px;
  height: 44px;
  font-family: "Nunito Sans";
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #333333;
  margin-top: 20px;
  margin-bottom: 20px;
  @media only screen and (min-width: 481px) and (max-width: 1080px) {
    width: 600px;
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 320px;
  }
`;

const PartnerContainer = styled.div`
  width: 1100px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  @media only screen and (min-width: 481px) and (max-width: 1080px) {
    width: 600px;
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 320px;
  }
`;

const SponsorItem = styled.div`
  width: 200px;
  margin-bottom: 20px;
  height: 100px;
  margin: 20px;
  @media only screen and (min-width: 481px) and (max-width: 1080px) {
    width: 100px;
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 100px;
    margin-right: 30px;
  }
`;

const Picture = styled.img`
  width: 100%;
  max-height: 75%;
`;

export default PartnerBanner;
