import { FETCHED_NEWSITEMS, FETCHED_ONE_NEWSITEM, CREATE_NEWSITEM, NEWSITEM_DELETED } from '../actions/news'

export default (state = [], { type, payload } = {}) => {
  switch(type) {
    case  FETCHED_NEWSITEMS:
      return [ ...payload ]

      case FETCHED_ONE_NEWSITEM :
           const newsItemIds = state.map(b => b._id)
          if (newsItemIds.indexOf(payload._id) < 0) {
            return [{ ...payload }].concat(state)
         }

         return state.map((newsItem) => {
           if (newsItem._id === payload._id) {
             return { ...payload }
           }
         return newsItem
       })

       case CREATE_NEWSITEM:
        const newnewsItem = { ...payload }
        return [newnewsItem].concat(state)

        case NEWSITEM_DELETED:
       return state.filter((newsItem) => (newsItem._id !== payload._id))

   default :
     return state

  }
}
