import React, { Component, Fragment } from "react";
import DeStichting from "../components/DeStichting";
import ParnterBanner from "../components/PartnerBanner";
import styled from "styled-components";
import overons from "../img/over-ons.jpeg";
import middleheader from "../img/middle-header.jpg";
import ProjectCard from "../projects/ProjectCard";
import NewsItemCard from "../news/NewsCard";
import FrontHeaderImg from "../img/front-header.jpg";
import SubscribePage from "../components/MailChimpForm";
import OverOnsPic from "../img/over-ons.jpeg";
import { FormattedMessage, injectIntl } from "react-intl";

import "./Home.css";
import { compose } from "redux";
import { withApiData } from "react-api-data";
import CustomGridLoader from "../Animations/CustomGridLoader";
import { css } from "@emotion/core";
import Appear from "../components/Appear";
import { Link } from "react-router-dom";
//import Routes from './routes'

const getProjects = (projects) =>
  projects.slice(Math.max(projects.length - 3)).reverse();

class Home extends Component {
  renderProject(project, index) {
    return <ProjectCard key={index} {...project} />;
  }

  rendernewsItem(newsItem, index, newsItems) {
    return <NewsItemCard key={index} {...newsItem} />;
  }

  linkToNews(e) {
    e.preventDefault();
    window.location = "/nieuws";
  }
  linkToOverOns(e) {
    e.preventDefault();
    window.location = "/overons";
  }

  render() {
    const { projects } = this.props;
    const { newsItems } = this.props;
    return (
      <Fragment>
        <FrontHeader>
          <p>
            Join our community <br />
            to support quality education
          </p>
        </FrontHeader>
        <CardContainer>
          <SubHeader>
            <FormattedMessage id="projects" defaultMessage="Projecten" />
          </SubHeader>
          <SubText>
            {" "}
            <FormattedMessage
              id="supportGrowth"
              defaultMessage="Hoe wij vanuit Nederland bijdragen aan de groei van Maya"
            />
          </SubText>

          <CardsHome>
            {projects.request.networkStatus === "success" &&
              getProjects(projects.data).map(this.renderProject)}
          </CardsHome>
          <CustomGridLoader
            sizeUnit={"px"}
            size={50}
            margin={"15px"}
            css={override}
            color={"#007ec9"}
            secondColor={"#f71635"}
            loading={projects.request.networkStatus === "loading"}
          />
          <MoreItems to={`/${this.props.intl.locale}/projects`}>
            <FormattedMessage
              id="moreProjects"
              defaultMessage="Meer projecten"
            />
          </MoreItems>
        </CardContainer>
        <Overons>
          <div>
            <h2>
              <FormattedMessage id="aboutUs" defaultMessage="Over ons" />
            </h2>
            <p>
              {" "}
              <FormattedMessage
                id="weSupportMaya"
                defaultMessage="Wij steunen Maya, een inspirerende scholengemeenschap in Nepal.
              Maya is een sociale beweging, opgezet door jonge Nepalezen die op
              een duurzame manier het land willen ontwikkelen. Hoogwaardig
              Engelstalig onderwijs en verankering in de lokale gemeenschap
              staan daarbij centraal."
              />
            </p>
            <Link
              to={`/${this.props.intl.locale}/aboutus`}
              style={{ textAlign: "left", width: "450px" }}
            >
              <FormattedMessage id="readMore" defaultMessage="Lees meer" />
            </Link>
          </div>
          <img src={overons} alt="overons" />
        </Overons>
        <CardContainer>
          <SubHeader>
            <FormattedMessage id="news" defaultMessage="Nieuws" />
          </SubHeader>
          <SubText>
            <FormattedMessage
              id="lastUpdates"
              defaultMessage="De laatste updates en nieuwsberichten over Maya"
            />
          </SubText>
          {newsItems.request.networkStatus === "success" && (
            <NewsCardsHome>
              {newsItems.data
                .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
                .slice(0, 6)
                .map(this.rendernewsItem.bind(newsItems))}
            </NewsCardsHome>
          )}
          <MoreItems to={`/${this.props.intl.locale}/news`}>
            <FormattedMessage
              id="moreNews"
              defaultMessage="Bekijk meer nieuws"
            />
          </MoreItems>
        </CardContainer>

        <MiddleHeader>
          <img src={middleheader} alt="overons" />
        </MiddleHeader>
        <DeStichting />
        <ParnterBanner />
        <div className="nieuwsbrief-head">
          <h8>
            {" "}
            <FormattedMessage
              id="stayUpdated"
              defaultMessage="Blijf op de hoogte via de nieuwsbrief"
            />
          </h8>
          <SubscribePage />
        </div>
      </Fragment>
    );
  }
}

const FrontHeader = styled(Appear)`
  max-width: 100%;
  width: 100%;
  height: 458px;
  background: url(${FrontHeaderImg}) no-repeat center center;
  background-size: cover;
  text-align: center;
  position: relative;
  transform: scale(1);
  overflow: hidden;
  background-position: 50% 20%;
  p {
    width: 795px;
    height: 120px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    margin-bottom: 243px;
    transform: translate(-50%, -50%);
    font-family: "Nunito Sans";
    font-size: 48px;
    font-weight: 600;
    line-height: 1.07;
    letter-spacing: 0;
    text-align: center;
    color: #ffffff;
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    background-size: cover;
    text-align: center;
    position: relative;
    p {
      width: 330px;
      height: 50px;
      font-family: "Nunito Sans";
      font-size: 24px;
      font-weight: 600;
      line-height: 1.04;
      letter-spacing: 0;
    }
  }
`;

const CardContainer = styled(Appear)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SubHeader = styled(Appear)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
  width: 198px;
  height: 33px;
  background-size: cover;
  font-family: "Nunito Sans";
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  color: #333333;
`;

const override = css`
  margin-top: 40px;
`;

const SubText = styled.h2`
  display: inline-block;
  width: 600px;
  height: 22px;
  margin-top: 0px;
  font-family: "Nunito Sans";
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  margin-top: 5px;
  color: #666666;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 300px;
  }
`;

const CardsHome = styled(Appear)`
  display: flex;
  width: 1100px;
  margin-top: -50px;
  justify-content: space-between;
  flex-direction: row;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 100%;
    margin-top: -10px;
    margin-left: 0px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
  }
`;

const MoreItems = styled(Link)`
  padding-top: 35px;
  height: 100px;
  width: 100%;
  position: relative;
  text-align: center;
  cursor: pointer;
  font-family: "Nunito Sans";
  font-size: 20px;
  font-weight: 600;
  color: #3b89ca;
  letter-spacing: 0;
  text-align: center;
  align-self: center;
  margin: auto;
`;

const Overons = styled(Appear)`
  width: 100%;
  height: 380px;
  background-color: rgba(0, 126, 201, 0.1);
  position: relative;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
  height: 407px;
  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  h2 {
    display: flex;
    padding-top: 20px;
    padding-left: 0px;
    width: 450px;
    height: 44px;
    font-family: "Nunito Sans";
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0;
    text-align: left;
    color: #333333;
  }
  p {
    padding-top: 10px;
    margin-left: 0px;
    width: 450px;
    height: 200px;
    font-family: "Nunito Sans";
    line-height: 1.67;
    font-size: 18px;
    letter-spacing: 0;
    text-align: left;
    color: #333333;
  }
  h3 {
    width: 450px;
    margin-top: 10px;
    font-family: "Nunito Sans";
    font-size: 18px;
    letter-spacing: 0;
    text-align: left;
    color: #333333;
    font-weight: 600;
    cursor: pointer;
  }
  img {
    margin: 0;
    float: right;
    height: 100% auto;
    width: 50% auto;
    padding: 0px;
    object-fit: cover;
  }
  @media only screen and (min-width: 275px) and (max-width: 1100px) {
    background: url(${OverOnsPic}) no-repeat center center;
    background-size: cover;
    margin-top: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    h2 {
      width: 102px;
      height: 38px;
      font-family: "Nunito Sans";
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0;
      text-align: center;
      color: #ffffff;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      position: relative;
      margin-top: 0px;
    }
    p {
      width: 290px;
      height: 230px;
      font-family: "Nunito Sans";
      font-size: 16px;
      font-weight: 400;
      line-height: 1.67;
      letter-spacing: 0;
      font-style: normal;
      font-stretch: normal;
      margin-top: 40px;
      text-align: center;
      color: #ffffff;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    }
    h3 {
      width: 114px;
      height: 42px;
      border-radius: 3px;
      background-color: #f58320;
      font-family: "Nunito Sans";
      font-size: 16px;
      padding-top: 12px;
      font-weight: 600;
      letter-spacing: 0;
      text-align: center;
      color: #ffffff;
      position: relative;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      vertical-align: middle;
    }
    img {
      display: none;
    }
  }
`;

const MiddleHeader = styled(Appear)`
  width: 100%;
  height: 435px;
  img {
    margin: 0;
    float: right;
    height: 100%;
    width: 100%;
    padding: 0px;
    object-fit: cover;
  }

  @media only screen and (min-width: 275px) and (max-width: 480px) {
    display: none;

    img {
      margin: 0;
      float: right;
      height: 100%;
      width: 100%;
      padding: 0px;
      object-fit: cover;
    }
  }
`;

const NewsCardsHome = styled(Appear)`
  width: 1100px;
  display: -webkit-flex; /* Safari */
  -webkit-align-items: center; /* Safari 7.0+ */
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  @media only screen and (min-width: 481px) and (max-width: 1080px) {
    width: 600px;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export default compose(
  injectIntl,
  withApiData(
    {
      projects: "getProjects",
      newsItems: "getNewsItems",
    },
    (ownProps, state) => ({
      projects: {
        isEnglish: ownProps.intl.locale === "en",
      },
      newsItems: {
        isEnglish: ownProps.intl.locale === "en",
      },
    })
  )
)(Home);
