import React from "react";
import styled from "styled-components";

export const linkStrategy = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(character => {
        const entityKey = character.getEntity();
        return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === "LINK"
        );
    }, callback);
};

export const Link = props => {

    const { contentState, entityKey } = props;
    const { url } = contentState.getEntity(entityKey).getData();
    return (
        <a
            href={url}
            rel="noopener noreferrer"
            target="_blank"
            aria-label={url}
        >
            {props.children}
        </a>
    );
};

const LinkPlugin = () => {
    return {
        decorators: [
            {
                strategy: linkStrategy,
                component: Link
            }
        ]
    }
};

const StyledLink = styled.p`
   color: blue;
   font-size: 20px;
   text-decoration: underline;
   
`;

export default LinkPlugin;