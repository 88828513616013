import React, { Fragment, useImperativeHandle, useRef } from "react";
import {
  AtomicBlockUtils,
  EditorState,
  RichUtils,
  CompositeDecorator,
} from "draft-js";
import Editor from "@draft-js-plugins/editor";
import "@draft-js-plugins/inline-toolbar/lib/plugin.css";
import styled from "styled-components";
import createVideoPlugin from "@draft-js-plugins/video";
import BlockStyleControls from "./BlockStyleControl";
import createLinkPlugin from "@draft-js-plugins/anchor";
import createImagePlugin from "@draft-js-plugins/image";
import createInlineToolbarPlugin from "@draft-js-plugins/inline-toolbar";
import "@draft-js-plugins/image/lib/plugin.css";

import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
} from "@draft-js-plugins/buttons";
import linkStyles from "./link.module.css";
import ReactCloudinaryUploader, { cloudinaryUpload } from "./CloudinaryUpdater";

import { mediaBlockRenderer } from "./MediaBlockRenderer";

const linkPlugin = createLinkPlugin({
  theme: linkStyles,
  placeholder: "http://…",
});

const videoPlugin = createVideoPlugin();

const inlineToolbarPlugin = createInlineToolbarPlugin();

const imagePlugin = createImagePlugin();

const { InlineToolbar } = inlineToolbarPlugin;

const options = {
  cloud_name: "djhc9jhiq",
  upload_preset: "FRONT_HEADER",
  tags: ["maya"],
  returnJustUrl: true,
};
const plugins = [inlineToolbarPlugin, linkPlugin, videoPlugin, imagePlugin];

const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};
class CustomEditor extends React.Component {
  state = {
    editorState: this.props.editorState,
  };
  onChange = (editorState) => {
    this.setState({ editorState });
    this.props.onChange("editorState", editorState);
  };

  handleKeyCommand = (command) => {
    const { editorState } = this.props;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  };

  onTab = (e) => {
    const maxDepth = 4;
    this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
  };

  toggleBlockType = (blockType) => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  };

  toggleStyle = (inlineStyle) => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    );
  };

  imageUpload = (e, editorState) => {
    e.preventDefault();
    /*eslint-disable */
    cloudinaryUpload(options)
      .then((image) => {
        console.log(image);
        this.onChange(imagePlugin.addImage(this.state.editorState, image));
        //   const contentState = this.state.editorState.getCurrentContent();
        //   const contentStateWithEntity = contentState.createEntity(
        //     "image",
        //     "IMMUTABLE",
        //     { src: image }
        //   );
        //   const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        //   const newEditorState = EditorState.set(
        //     this.state.editorState,
        //     { currentContent: contentStateWithEntity },
        //     "create-entity"
        //   );
        //   this.props.onChange(
        //     "editorState",
        //     AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ")
        //   );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  videoUpload = () => {
    const url = window.prompt("Zet je link hier");
    if (url && url.length > 0) {
      this.onChange(videoPlugin.addVideo(this.state.editorState, { src: url }));
    }
  };

  focus = () => this.editor.focus();

  render() {
    return (
      <RichEditorContainer>
        <BlockStyleControls
          editorState={this.state.editorState}
          onToggle={this.toggleBlockType}
          onToggleStyle={this.toggleStyle}
          onImageUpload={this.imageUpload}
          onVideoUpload={this.videoUpload}
        />

        <RichEditor onClick={this.focus}>
          <Editor
            // customStyleMap={styleMap}
            plugins={plugins}
            blockRendererFn={mediaBlockRenderer}
            editorState={this.state.editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.onChange}
            onTab={this.onTab}
            ref={(element) => {
              this.editor = element;
            }}
            spellCheck={true}
          />
        </RichEditor>

        <InlineToolbar>
          {// may be use React.Fragment instead of div to improve perfomance after React 16
          (externalProps) => (
            <div>
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <linkPlugin.LinkButton {...externalProps} />
            </div>
          )}
        </InlineToolbar>
      </RichEditorContainer>
    );
  }
}
const RichEditorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 350px;
  font-size: 18px;
  border-radius: 5px;
  padding-left: 5px;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid grey;
  margin-bottom: 20px;
  max-width: 850px;
  width: 950px;
`;

const RichEditor = styled.div`
  border-top: 1px solid grey;
  margin-right: 5px;
  margin-left: 3px;
  width: 99%;
  padding: 2px;
  padding-bottom: 10px;
  padding-top: 10px;
  cursor: text;
`;

export default CustomEditor;
