import React from 'react'
import styled from "styled-components";
import Product from './Product'
import './CheckoutCart.css'
import {Link} from "react-router-dom";


const Cart  = ({ products, total, onCheckoutClicked, isThankYou, isCheckout }) => {
  const hasProducts = products.length  > 0
  const nodes = hasProducts ? (
    products.map(product =>
      <Product
        title={isThankYou ? product.name : `${product.title} - ${product.color} - ${product.size}`}
        price={product.price || product.unitPrice.value}
        quantity={product.quantity}
        isCheckout={isCheckout}
        id={product._id}
        key={product._id}
      />
    )
  ) : (
    null
  )
  return (

    <ShoppingCartCheckout>
    <HeaderCart>Je bestelling</HeaderCart>
      <ProductsCart>{nodes}</ProductsCart>
      <CartLine/>
      <Total>Totaal=&#8364;{total}</Total>
    </ShoppingCartCheckout>
  )
};

const ShoppingCartCheckout = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 33px;
  min-height: 200px;
  border-radius: 3px;
  margin-top: 37px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
    @media only screen and (min-width: 275px) and (max-width: 430px){
        width: 270px;
        min-height: 250px;
    }
`;

const HeaderCart = styled.p`
  font-family: 'Nunito Sans';
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: left;
  color: #333333;
  position: relative;
`;

const Total = styled.p`
    font-family: 'Nunito Sans';
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    margin-top: 5px;
    letter-spacing: 0px;
    text-align: left;
    color: #333333;
`;

const ProductsCart = styled.div`
        min-height: 105px;
        margin-top: 10px;
        font-family: 'Nunito Sans';
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        text-align: left;
        color: #666666;
`;

const CartLine = styled.div`
        margin-top: 20px;
        width: 235px;
        height: 2px;
        border: solid 1px #bcbcbc;
`;

export default Cart
