import projects from './projects'
import newsItems from './news'
import counter from './counter'
import { reducer as apiData } from 'react-api-data';
import currentUser from './currentUser'
import cart, * as fromCart from './cart'
import products, * as fromProducts from './products'
import {combineReducers} from "redux";
import payment from "./payment";

const rootReducer = combineReducers({
    cart,
    products,
    projects,
    currentUser,
    newsItems,
    payment,
    apiData,
});

export default rootReducer

const getAddedIds = state => fromCart.getAddedIds(state.cart);
export const getQuantity = (state, id) => fromCart.getQuantity(state.cart, id);
const getProduct = (state, id) => fromProducts.getProduct(state.products, id);

export const getTotalAmountOfProducts = state => {
    return getAddedIds(state).reduce((acc, id) => acc + getQuantity(state, id), 0);
};

export const getStockPerProduct = (state, id) => (getProduct(state, id).stock);

export const getColorPerProduct = (state, id) => getProduct(state, id).color

export const getTotal = state =>
  getAddedIds(state)
    .reduce((total, id) =>
      total + (getProduct(state, id).price * getQuantity(state, id)),
      0
    )
    .toFixed(2);


export const getCartProducts = state =>
  getAddedIds(state).map(id => ({
    ...getProduct(state, id),
    quantity: getQuantity(state, id)
  }));
