import React, { Fragment } from "react";
import Icon from "@material-ui/core/Icon";
import styled from "styled-components";

const BLOCK_TYPES = [
  { label: "title", style: "header-one", icon: true },
  { label: "format_list_bulleted", style: "unordered-list-item", icon: true },
  { label: "format_list_numbered", style: "ordered-list-item", icon: true },
  //   { label: "format_link", style: "LINK", icon: true },
];

const STYLE_TYPES = [
  { label: "format_bold", style: "BOLD", icon: true },
  { label: "format_italic", style: "ITALIC", icon: true },
  { label: "format_underline", style: "UNDERLINE", icon: true },
];

const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  const onToggleStyle = (e, style) => {
    e.preventDefault();
    props.onToggleStyle(style);
  };
  const onToggle = (e, style) => {
    e.preventDefault();
    props.onToggle(style);
  };
  const currentStyle = props.editorState.getCurrentInlineStyle();
  console.log(props);
  return (
    <BlockStyleContainer>
      {STYLE_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onMouseDown={(e) => onToggleStyle(e, type.style)}
        >
          {type.icon ? <Icon>{type.label}</Icon> : type.label}
        </StyleButton>
      ))}
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onMouseDown={(e) => onToggle(e, type.style)}
        >
          {type.icon ? <Icon>{type.label}</Icon> : type.label}
        </StyleButton>
      ))}
      <StyleButton onMouseDown={(e) => props.onImageUpload(e)}>
        <Icon>insert_photo</Icon>
      </StyleButton>
      <StyleButton onMouseDown={(e) => props.onVideoUpload()}>
        <Icon>video_call</Icon>
      </StyleButton>
    </BlockStyleContainer>
  );
};
const BlockStyleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  max-width: 60%;
  user-select: none;
`;
// eslint-disable-next-line no-undef
const StyleButton = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: ${(props) => (props.active ? "#007ec9" : "black")};
  text-align: center;
  align-items: center;
  justify-content: center;
  &:hover {
    background: rgb(235, 235, 235);
  }
`;

export default BlockStyleControls;
