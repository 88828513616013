import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { subscribe } from "../actions/mailchimp";
import TextField from "@material-ui/core/TextField";
import "./MailChimpForm.css";
import { FormattedMessage } from "react-intl";

const style = {
  width: "120px",
  height: "55px",
  fontFamily: "Nunito Sans",
  textAlign: "center",
  textTransform: "lowercase",
};

const labelstyle = {
  fontFamily: "Nunito Sans",
  position: "relative",
  fontSize: "18px",
  paddingTop: "60px",
  textTransform: "Capitalize",
  textAlign: "center",
  verticalAlign: "center",
};

class SubscribePage extends React.Component {
  static propTypes = {
    subscribe: PropTypes.func.isRequired,
  };

  componentWillMount() {}

  submitForm(event) {
    event.preventDefault();
    const contact = {
      email: this.refs.email.getValue(),
    };
    this.props.subscribe(contact);
    this.refs.form.reset();
  }

  render() {
    return (
      <div className="form-subscribe">
        <form onSubmit={this.submitForm.bind(this)} ref="form">
          <div>
            <TextField
              ref="email"
              type="email"
              hintText="E-mail"
              underlineShow={false}
              inputStyle={{
                backgroundColor: "#ffffff",
                height: "55px",
                fontSize: "20px",
              }}
              hintStyle={{
                color: "rgba(0,0,0,.26)",
                zIndex: "1",
                bottom: "10px",
                left: "5px",
                fontSize: "20px",
              }}
            />
          </div>
        </form>
        <button className="aboneer-button" onClick={this.submitForm.bind(this)}>
          <FormattedMessage id="subscribe" defaultMessage="Abonneer" />
        </button>
      </div>
    );
  }
}

const mapStateToProps = ({ contact }) => ({ contact });

export default connect(mapStateToProps, { subscribe })(SubscribePage);
