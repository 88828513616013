import React, { Fragment } from "react";
import { compose, lifecycle } from "recompose";
import styled from "styled-components";
import { Form, Field, withFormik, FieldArray } from "formik";

import { Img } from "react-image-loading";
import AddIcon from "@material-ui/icons/Add";
import { ButtonConfirm } from "../sponsorartikelen/CheckoutForm";
import Icon from "@material-ui/core/Icon";
import { CustomFab, CustomSmallFab } from "./NewsItemsListCms";
import CloseIcon from "@material-ui/icons/Close";
import CustomEditor from "./CustomEditor";
import {
  ContentState,
  convertFromRaw,
  convertToRaw,
  EditorState,
} from "draft-js";

const AboutUsForm = (props) => {
  const onUploadSliderPicture = (arrayHelpers) => {
    /*eslint-disable */
    cloudinary.openUploadWidget(
      {
        cloud_name: "djhc9jhiq",
        upload_preset: "FRONT_HEADER",
        tags: ["maya"],
      },
      /*eslint-enable */
      function(error, result) {
        if (error) {
          console.log(error);
        } else {
          if (result.info.url) {
          return arrayHelpers.push(result.info.url);
          }
        }
      }
    );
  };
  const onUploadThumbPicture = (setFieldValue, fieldValue) => {
    /*eslint-disable */
    cloudinary.openUploadWidget(
      {
        cloud_name: "djhc9jhiq",
        upload_preset: "FRONT_HEADER",
        tags: ["maya"],
      },
      /*eslint-enable */
      function(error, result) {
        if (error) {
          console.log(error);
        } else {
          if (result.info.url) {
          return setFieldValue(fieldValue, result[0].url);
          }
  
        }
      }
    );
  };
  const clearValue = (setFieldValue, fieldValue) =>
    setFieldValue(fieldValue, "");
  const handleToggle = (fieldValue) => {
    props.setFieldValue(fieldValue, !props.values.hasSizes);
  };
  return (
    <Fragment>
      <CreateNewsForm>
        <Label>Titel</Label>
        <FormInput type="text" name="title" />
        <Label>Header foto</Label>
        <ImageUpload>
          {!props.values.headerPhoto.length > 0 ? (
            <CustomFab
              color="primary"
              aria-label="add"
              onClick={() =>
                onUploadThumbPicture(props.setFieldValue, "headerPhoto")
              }
            >
              <AddIcon />
            </CustomFab>
          ) : (
            <ImageWrapper>
              <CustomSmallFab
                onClick={() => clearValue(props.setFieldValue, "headerPhoto")}
              >
                <CloseIcon />
              </CustomSmallFab>
              <ImagePreview src={props.values.headerPhoto}></ImagePreview>
            </ImageWrapper>
          )}
        </ImageUpload>
        <Label>tekst</Label>
        <CustomEditor
          editorState={props.values.editorState}
          onChange={props.setFieldValue}
          onBlur={props.handleBlur}
        />
        <Label style={{ marginBottom: "10px" }}>Samenvattende tekst</Label>
        <FormArea
          type="text"
          style={{ width: "100%" }}
          name={`summary`}
          component="textarea"
        />
        <Label>Slider foto's(600x400)</Label>
        <FieldArray
          name="imageSlider"
          render={(arrayHelpers) => (
            <ImageSliderList>
              {props.values.imageSlider.map((image, index) => (
                <ImageSliderUpload>
                  <ImageWrapper>
                    <CustomSmallFab onClick={() => arrayHelpers.remove(index)}>
                      <CloseIcon />
                    </CustomSmallFab>
                    <ImageSliderPreview src={image} />
                  </ImageWrapper>
                </ImageSliderUpload>
              ))}
              <ImageSliderUpload>
                <CustomFab
                  color="primary"
                  aria-label="add"
                  onClick={() => onUploadSliderPicture(arrayHelpers)}
                >
                  <AddIcon />
                </CustomFab>
              </ImageSliderUpload>
            </ImageSliderList>
          )}
        />
        <ButtonWrapper>
          {!props.values.published && (
            <ConfirmButton
              onClick={() => props.handleSubmit()}
              disabled={props.isSubmitting}
            >
              Opslaan
            </ConfirmButton>
          )}
          <ConfirmButton
            onClick={(e) => {
              props.setFieldValue("published", true, false);
              props.handleSubmit(e);
            }}
            disabled={props.isSubmitting}
          >
            Opslaan en publiceren
          </ConfirmButton>
        </ButtonWrapper>
      </CreateNewsForm>
    </Fragment>
  );
};

const CreateNewsForm = styled(Form)`
  display: flex;
  width: 100%;
  padding: 25px;
  flex-direction: column;
  overflow: "scroll";
  background-color: white;
`;

const ConfirmButton = styled(ButtonConfirm)`
  float: right;
  margin-left: 10px;
`;

const AddSquareButton = styled(ButtonConfirm)`
  background-color: #007ec9;
  padding: 0;
`;

const Label = styled.p`
  font-size: 16px;
  font-family: "Arial";
  margin-bottom: 5px;
`;

const FormArea = styled(Field)`
  height: 70px;
  font-size: 18px;
  border-radius: 5px;
  padding-left: 5px;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid grey;
  margin-bottom: 20px;
  max-width: 850px;
  width: 850px;
`;

const FormInput = styled(Field)`
  width: 100%;
  height: 30px;
  font-size: 18px;
  border-radius: 5px;
  padding-left: 5px;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid grey;
  margin-bottom: 10px;
  max-width: 850px;
  width: 850px;
`;

const ImageSliderPreview = styled(Img)`
  max-width: 300px;
  min-width: 300px;
  min-height: 200px;
  max-height: 200px;
  border-radius: 5px;
  border: 1px solid black;
`;

const ImageSliderUpload = styled.div`
  display: flex;
  min-width: 300px;
  max-width: 300px;
  min-height: 200px;
  max-height: 200px;
  margin-bottom: 20px;
  margin-right: 20px;
  border-radius: 5px;
  border: 1px solid black;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ImageSliderList = styled.div`
  display: flex;
  width: 100%;
  min-height: 100px;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
`;

export const SwitchItem = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 10px;
  font-size: 18px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 0;
    margin-bottom: 20px
    justify-content: flex-end;
    align-items: right;
`;

const ColorPreview = styled.div`
  display: flex;
  width: 50px;
  height: 30px;
  margin-left: 5px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: ${(props) => props.backgroundColor};
  padding: 0;
`;
const ImageUpload = styled.div`
  display: flex;
  min-width: 900px;
  max-width: 900px;
  min-height: 300px;
  max-height: 300px;
  margin-bottom: 20px;
  margin-right: 20px;
  border-radius: 5px;
  border: 1px solid black;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ImagePreview = styled(Img)`
  max-width: 900px;
  min-width: 900px;
  min-height: 300px;
  max-height: 300px;
  border-radius: 5px;
  border: 1px solid black;
`;

const ImageWrapper = styled.div`
  max-width: 100%;
  position: relative;
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
`;

export default compose(
  withFormik({
    mapPropsToValues: (props) => {
      if (props.isEdit) {
        const hasEditorState =
          props.data &&
          props.data.content &&
          props.data.content.charAt(0) === "{";
        return {
          title: props.data.title,
          imageSlider: props.data.imageSlider ? props.data.imageSlider : [],
          editorState: hasEditorState
            ? EditorState.createWithContent(
                convertFromRaw(JSON.parse(props.data.content))
              )
            : EditorState.createWithContent(
                ContentState.createFromText(
                  props.data.content ? props.data.content : ""
                )
              ),
          headerPhoto: props.data.headerPhoto ? props.data.headerPhoto : "",
          summary: props.data.summary,
        };
      }
    },
    handleSubmit: (values, { props }) => {
      const contentState = values.editorState.getCurrentContent();
      const content = JSON.stringify(convertToRaw(contentState));
      const submitValues = {
        title: values.title,
        content,
        headerPhoto: values.headerPhoto,
        imageSlider: values.imageSlider,
        summary: values.summary,
        isEnglish: props.isEnglish,
      };
      props.submitEndpoint.perform({ id: props.id }, submitValues);
    },
    displayName: "MyForm",
  })
)(AboutUsForm);
