import React, { useState } from "react";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { useApiData } from "react-api-data";
import { TabContainer } from "./NewsItemEdit";
import DeStichtingEditForm from "./DeStichtingEditForm";
import LocaleSwitch from "./LocaleSwitch";

const DeStichtingEdit = (props) => {
  const { loggedIn } = props;
  const [language, setLanguage] = useState("dutch");
  const deStichting = useApiData("getDeStichting", {
    isEnglish: language === "english" ? "true" : "false",
  });
  console.log();
  const editDeStichting = useApiData("editDeStichting");
  if (loggedIn) {
    return (
      <TabContainer>
        <LocaleSwitch language={language} setLanguage={setLanguage} />

        {deStichting.request.networkStatus === "success" &&
          language === "english" && (
            <DeStichtingEditForm
              data={deStichting.data}
              id={deStichting.data && deStichting.data._id}
              submitEndpoint={editDeStichting}
              currentUser={props.currentUser}
              history={props.history}
              isEnglish
              isEdit
            />
          )}
        {deStichting.request.networkStatus === "success" &&
          language === "dutch" && (
            <DeStichtingEditForm
              data={deStichting.data}
              id={deStichting.data && deStichting.data._id}
              submitEndpoint={editDeStichting}
              currentUser={props.currentUser}
              history={props.history}
              isEdit
            />
          )}
      </TabContainer>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => ({
  loggedIn: !!state.currentUser,
  currentUser: state.currentUser,
});

export default compose(
  connect(mapStateToProps, null),
  lifecycle({
    componentDidMount() {
      if (!this.props.loggedIn) {
        this.props.history.push("/cms");
      }
    },
  })
)(DeStichtingEdit);
