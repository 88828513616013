import { createMuiTheme } from "@material-ui/core/styles";

// Colors
export const green = "#00AA86";
export const red = "#B71C1C";
export const darkRed = "#C1272D";
export const white = "#ffffff";
export const black = "#000000";
export const darkGrey = "#757575";
export const grey = "#DEDEDE";
export const grey50 = "rgba(222, 222, 222, 0.5)";
export const grey30 = "rgba(222, 222, 222, 0.7)";
export const orange = "#f58320";
// Palette
export const theme = createMuiTheme({
  palette: {
    primary1Color: orange,
    primary2Color: orange,
    primary3Color: green,
    accent1Color: green,
    textColor: black,
    alternateTextColor: white,
    canvasColor: white,
    borderColor: grey,
    disabledColor: grey30,
  },
});

export default theme;
