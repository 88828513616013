import React from 'react'
import styled from "styled-components";
import shoppingbasketimg from '../img/Winkelmandje.svg'


const ShoppingBasket = ({ amount}) => (
    <ShoppingBasketContainer>
        <div>
            <ShoppingBasketImage src={shoppingbasketimg}/>
            <ShoppingCircle>{amount}</ShoppingCircle>
        </div>
    </ShoppingBasketContainer>
);

const ShoppingBasketContainer = styled.div`
    position: relative;
    top: -5px;
    right: 20px;
    width: 40px;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 65px;
    
     @media only screen and (min-width: 275px) and (max-width: 480px) {
        position: relative;
        right: 0;
        padding-left: 40px;
        top: 5px;
     }
`;

const ShoppingBasketImage = styled.img`
    width: 40px;
    height: 30px;
`;

const ShoppingCircle = styled.div`
        position: absolute;
        top: 4px;
        right: -10px;
        min-width: 25px;
        min-height: 25px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        font-size: 12px;
        color: #fff;
        background-color: #f71635;
        line-height: 25px
        text-align: center;
        
     @media only screen and (min-width: 275px) and (max-width: 480px) {
        right: -30px;
     }
`;


export default ShoppingBasket