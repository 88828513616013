import React, { PureComponent } from "react";

import "./NewsCard.css";

import { compose } from "recompose";

import { withRouter, Link } from "react-router-dom";
import { Img } from "react-image-loading";
import { injectIntl } from "react-intl";

const NewsItemCard = (props) => {
  const { title, summary, headerPicture, _id } = props;
  console.log(props);
  return (
    <Link class="news-card" to={`/${props.intl.locale}/${_id}`}>
      <Img
        src={headerPicture}
        alt="Maya-NewsCard"
        style={{ width: "100%", height: "100%" }}
      />
      <h4 class="news-card-title">
        <b>{title}</b>
      </h4>
    </Link>
  );
};

export default compose(withRouter, injectIntl)(NewsItemCard);
