import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './styles/fonts.css'
import App from './App';
import store, { persistor } from './store'
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react'
import {configureApiData} from "react-api-data";
import {endpointConfig} from "./api/endpointConfig";
import ScrollToTop from "./components/ScrollToTop";


store.dispatch(configureApiData({}, endpointConfig));

ReactDOM.render(
  <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
              <ScrollToTop>
                    <App />
              </ScrollToTop>
          </BrowserRouter>
      </PersistGate>
  </Provider>, document.getElementById('root'))
