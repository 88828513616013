import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import styled from "styled-components";
import CmsTable from "./CmsTable";
import { withApiData } from "react-api-data";
import { GridLoader } from "react-spinners";
import AddIcon from "@material-ui/icons/Add";
import { css } from "@emotion/core";
import moment from "moment";
import { CustomFab } from "./NewsItemsListCms";

const columns = [
  { id: "name", label: "Artikel naam", maxWidth: 170 },
  { id: "color", label: "kleur", maxWidth: 170 },
  { id: "created", label: "Gemaakt op", maxWidth: 100 },
  { id: "publicated", label: "Gepubliceerd", maxWidth: 50 },
  { id: "edit", label: "edit", maxWidth: 50 },
  { id: "delete", label: "delete", maxWidth: 50 },
];

const getRows = (data) =>
  data.map((item) => ({
    id: item._id,
    name: item.title,
    color: item.color,
    created: moment(item.startDate).format("DD-MM-YYYY"),
    publicated: item.published,
    edit: "artikelen",
    delete: "artikelen",
  }));

const ArticleListCms = (props) => {
  const { loggedIn } = props;
  if (loggedIn) {
    return (
      <TabContainer>
        <Title>Sponsorartikelen</Title>
        {props.articles.request.networkStatus === "success" && (
          <Fragment>
            <CmsTable
              columns={columns}
              rows={getRows(props.articles.data)}
              history={props.history}
              deleteAction={props.deleteArticle}
            />
            <ButtonWrapper>
              <CustomFab
                color="primary"
                aria-label="add"
                onClick={() =>
                  props.history.push("/cms/container/artikelen/create")
                }
              >
                <AddIcon />
              </CustomFab>
            </ButtonWrapper>
          </Fragment>
        )}
        <GridLoader
          sizeUnit={"px"}
          size={50}
          margin={"15px"}
          css={override}
          color={"#007ec9"}
          loading={
            props.articles.request.networkStatus === "loading" ||
            props.deleteArticle.request.networkStatus === "loading"
          }
        />
      </TabContainer>
    );
  } else {
    return null;
  }
};

const TabContainer = styled.div`
  display: flex;
  width: 90%;
  margin: 100px 35px 35px 35px;
  padding: 25px;
  max-height: 90%;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  flex-direction: column;
  background-color: white;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  flex-direction: row-reverse;
`;

const Title = styled.h2`
  font-size: 23px;
  width: 100%;
  margin-bottom: 25px;
  margin-left: 16px;
`;

const override = css`
  margin: auto;
`;

const mapStateToProps = (state) => ({
  loggedIn: !!state.currentUser,
  currentUser: state.currentUser,
});
export default compose(
  withApiData({
    articles: "getCmsProducts",
    deleteArticle: "deleteProduct",
  }),
  connect(mapStateToProps, null),
  lifecycle({
    componentDidMount() {
      if (!this.props.loggedIn) {
        this.props.history.push("/cms");
      }
    },
  })
)(ArticleListCms);
