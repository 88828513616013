import React, { Component, Fragment, useState } from "react";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import CmsTable from "./CmsTable";
import { withApiData, useApiData } from "react-api-data";
import { GridLoader } from "react-spinners";
import LocaleSwitch from "./LocaleSwitch";
import CustomGridLoader from "../Animations/CustomGridLoader";
import { css } from "@emotion/core";
import AddIcon from "@material-ui/core/SvgIcon/SvgIcon";
import { ButtonWrapper, CustomFab } from "./NewsItemsListCms";

const columns = [
  { id: "name", label: "Project titel", minWidth: 300 },
  { id: "author", label: "Autheur", minWidth: 150 },
  { id: "created", label: "Gemaakt op", minWidth: 150 },
  { id: "publicated", label: "Gepubliceerd", minWidth: 150 },
  { id: "edit", label: "edit", maxWidth: 50 },
  { id: "delete", label: "delete", maxWidth: 50 },
];

const getRows = (data) =>
  data.map((item) => ({
    id: item._id,
    name: item.title,
    author: item.user && item.user.length > 0 ? item.user[0].name : "",
    created: item.startDate,
    publicated: item.published,
    edit: "projects",
    delete: "projects",
  }));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: "100%",
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
}));

const ProjectListCms = (props) => {
  const { loggedIn } = props;
  const [language, setLanguage] = useState("dutch");
  const projects = useApiData("getCmsProjects", {
    isEnglish: language === "english" ? "true" : "false",
  });
  if (loggedIn) {
    return (
      <TabContainer>
        <LocaleSwitch language={language} setLanguage={setLanguage} />
        <Title>Projecten</Title>
        {projects.request.networkStatus === "success" && (
          <Fragment>
            <CmsTable
              columns={columns}
              rows={getRows(projects.data)}
              history={props.history}
              language={language}
              deleteAction={props.deleteProject}
            />
            <ButtonWrapper>
              <CustomFab
                color="primary"
                aria-label="add"
                onClick={() =>
                  props.history.push("/cms/container/projects/create", {
                    isEnglish: language === "english",
                  })
                }
              >
                <AddIcon />
              </CustomFab>
            </ButtonWrapper>
          </Fragment>
        )}
        }
        <GridLoader
          sizeUnit={"px"}
          size={50}
          margin={"15px"}
          css={override}
          color={"#007ec9"}
          loading={projects.request.networkStatus === "loading"}
        />
      </TabContainer>
    );
  } else {
    return null;
  }
};

const TabContainer = styled.div`
  display: flex;
  width: 90%;
  margin: 100px 35px 35px 35px;
  padding: 25px;
  max-height: 90%;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  flex-direction: column;
  background-color: white;
`;

const Title = styled.h2`
  font-size: 23px;
  width: 100%;
  margin-bottom: 25px;
  margin-left: 16px;
`;

const override = css`
  margin: auto;
`;

const mapStateToProps = (state) => ({
  loggedIn: !!state.currentUser,
  currentUser: state.currentUser,
});
export default compose(
  withApiData({
    projects: "getCmsProjects",
    deleteProject: "deleteProject",
  }),
  connect(mapStateToProps, null),
  lifecycle({
    componentDidMount() {
      if (!this.props.loggedIn) {
        this.props.history.push("/cms");
      }
    },
  })
)(ProjectListCms);
