import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import styled from "styled-components";
import { withApiData } from "react-api-data";
import EditForm from "./EditForm";

const NewsItemEdit = (props) => {
  const { loggedIn } = props;
  if (loggedIn) {
    return (
      <TabContainer>
        {props.newsItem.request.networkStatus === "success" && (
          <EditForm
            newsItem={props.newsItem.data}
            isEnglish={props.history.location.state.isEnglish}
            submitEndpoint={props.editNewsItem}
            currentUser={props.currentUser}
            history={props.history}
            isEdit
          />
        )}
      </TabContainer>
    );
  } else {
    return null;
  }
};

export const TabContainer = styled.div`
  display: flex;
  width: 90%;
  margin: 100px 35px 35px 35px;
  padding: 25px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  flex-direction: column;
  background-color: white;
`;

const mapStateToProps = (state) => ({
  loggedIn: !!state.currentUser,
  currentUser: state.currentUser,
});
export default compose(
  withApiData(
    {
      newsItem: "getNewsItem",
      editNewsItem: "editNewsItem",
    },
    (ownProps) => ({
      newsItem: { id: ownProps.location.state.id },
      editNewsItem: { id: ownProps.location.state.id },
    })
  ),
  connect(mapStateToProps, null),
  lifecycle({
    componentDidMount() {
      if (!this.props.loggedIn) {
        this.props.history.push("/cms");
      }
    },
  })
)(NewsItemEdit);
