import { FETCHED_PROJECTS, FETCHED_ONE_PROJECT, CREATE_PROJECT, PROJECT_DELETED, UPDATE_PROJECT } from '../actions/projects'

export default (state = [], { type, payload } = {}) => {
  switch(type) {
    case  FETCHED_PROJECTS:
      return [ ...payload ]

      case FETCHED_ONE_PROJECT :
           const projectIds = state.map(b => b._id)
          if (projectIds.indexOf(payload._id) < 0) {
            return [{ ...payload }].concat(state)
         }

         return state.map((project) => {
           if (project._id === payload._id) {
             return { ...payload }
           }
         return project
       })

       case CREATE_PROJECT:
        const newProject = { ...payload }
        return [newProject].concat(state)

        case UPDATE_PROJECT:
         return state.map((project) => {
        if (project._id === payload._id) {
          return { ...payload }
        }
        return project
      })

        case PROJECT_DELETED:
       return state.filter((project) => (project._id !== payload._id))

   default :
     return state

  }
}
