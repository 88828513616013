



   export const cloudinaryUpload = (options) => {
        return new Promise((fulfil, reject) => {
            /*eslint-disable */
            cloudinary.openUploadWidget(options,
                (error, result) => {
                    console.log(result);
                    if (error) {
                        reject(error);
                        return false;
                    }
                    if (!result || result.length === 0) {
                        reject(new Error("No result from Cloudinary"));
                        return false;
                    }
                    if(result.length > 0) {

                        console.log(result[0].url)
                        fulfil(result[0].url);
                    }
                    return true;
                }
            );
        });
    }
