import React, { Fragment } from "react";
import { compose } from "recompose";
import styled from "styled-components";
import { Form, Field, FieldArray, withFormik } from "formik";
import {
  EditorState,
  convertFromRaw,
  convertToRaw,
  ContentState,
} from "draft-js";
import { createEditorStateWithText } from "@draft-js-plugins/editor";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { CustomFab, CustomSmallFab } from "./NewsItemsListCms";
import { Img } from "react-image-loading";
import CustomEditor from "./CustomEditor";
import { ButtonConfirm } from "../sponsorartikelen/CheckoutForm";
import Switch from "@material-ui/core/Switch";
import { SwitchItem } from "./ArticleEditForm";

const ProjectEditForm = (props) => {
  const onUploadPicture = (setFieldValue, fieldValue) => {
    /*eslint-disable */
    cloudinary.openUploadWidget(
      {
        cloud_name: "djhc9jhiq",
        upload_preset: "FRONT_HEADER",
        tags: ["maya"],
      },
      /*eslint-enable */
      function(error, result) {
        if (error) {
        } else {
          if (result.info.url) {
          return setFieldValue(fieldValue, result.info.url);
          }
        }
      }
    );
  };

  const onUploadSliderPicture = (arrayHelpers) => {
    /*eslint-disable */
    cloudinary.openUploadWidget(
      {
        cloud_name: "djhc9jhiq",
        upload_preset: "FRONT_HEADER",
        tags: ["maya"],
      },
      /*eslint-enable */
      function(error, result) {
        if (error) {
          console.log(error);
        } else {
          if (result.info.url) {
          return arrayHelpers.push(result.info.url);
          }
        }
      }
    );
  };
  const clearValue = (setFieldValue, fieldValue) =>
    setFieldValue(fieldValue, "");
  const handleToggle = (fieldValue) => {
    props.setFieldValue(fieldValue, !props.values.finished);
  };
  return (
    <Fragment>
      <CreateNewsForm>
        <Label>Titel</Label>
        <FormInput type="text" name="title" />
        <Label>Samenvatting(max 35 woorden)</Label>
        <FormArea type="text" name="summary" component="textarea" />
        <Label>Header foto(1000x450)</Label>
        <ImageUpload>
          {!props.values.headerPicture.length > 0 ? (
            <CustomFab
              color="primary"
              aria-label="add"
              onClick={() =>
                onUploadPicture(props.setFieldValue, "headerPicture")
              }
            >
              <AddIcon />
            </CustomFab>
          ) : (
            <ImageWrapper>
              <CustomSmallFab
                onClick={() => clearValue(props.setFieldValue, "headerPicture")}
              >
                <CloseIcon />
              </CustomSmallFab>
              <ImagePreview src={props.values.headerPicture}></ImagePreview>
            </ImageWrapper>
          )}
        </ImageUpload>
        <SwitchItem>
          <Field
            label="Dit product heeft maten"
            name="hasSizes"
            component={Switch}
            checked={props.values.finished}
            onChange={() => handleToggle("finished")}
          />
          <Label style={{ lineHeight: "40px" }}>Dit project is voltooid</Label>
        </SwitchItem>
        <Label>Content</Label>
        <CustomEditor
          editorState={props.values.editorState}
          onChange={props.setFieldValue}
          onBlur={props.handleBlur}
        />
        <Label>Slider foto's(600x400)</Label>
        <FieldArray
          name="imageSlider"
          render={(arrayHelpers) => (
            <ImageSliderList>
              {props.values.imageSlider.map((image, index) => (
                <ImageSliderUpload>
                  <ImageWrapper>
                    <CustomSmallFab onClick={() => arrayHelpers.remove(index)}>
                      <CloseIcon />
                    </CustomSmallFab>
                    <ImageSliderPreview src={image} />
                  </ImageWrapper>
                </ImageSliderUpload>
              ))}
              <ImageSliderUpload>
                <CustomFab
                  color="primary"
                  aria-label="add"
                  onClick={() => onUploadSliderPicture(arrayHelpers)}
                >
                  <AddIcon />
                </CustomFab>
              </ImageSliderUpload>
            </ImageSliderList>
          )}
        />
        <ButtonWrapper>
          {!props.values.published && (
            <ConfirmButton
              onClick={() => props.handleSubmit()}
              disabled={props.isSubmitting}
            >
              Opslaan
            </ConfirmButton>
          )}
          <ConfirmButton
            onClick={(e) => {
              props.setFieldValue("published", true, false);
              props.handleSubmit(e);
            }}
            disabled={props.isSubmitting}
          >
            Opslaan en publiceren
          </ConfirmButton>
        </ButtonWrapper>
      </CreateNewsForm>
    </Fragment>
  );
};

const CreateNewsForm = styled(Form)`
  display: flex;
  width: 100%;
  padding: 25px;
  flex-direction: column;
  overflow: "scroll";
  background-color: white;
`;

const ConfirmButton = styled(ButtonConfirm)`
  float: right;
  margin-left: 10px;
`;

const Label = styled.p`
  font-size: 16px;
  font-family: "Arial";
  margin-bottom: 5px;
`;

const FormInput = styled(Field)`
  width: 100%;
  height: 30px;
  font-size: 18px;
  border-radius: 5px;
  padding-left: 5px;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid grey;
  margin-bottom: 20px;
  max-width: 850px;
  width: 850px;
`;

const ImageSliderList = styled.div`
  display: flex;
  width: 100%;
  min-height: 100px;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
`;

const ImageSliderPreview = styled(Img)`
  max-width: 300px;
  min-width: 300px;
  min-height: 200px;
  max-height: 200px;
  border-radius: 5px;
  border: 1px solid black;
`;

const ImageWrapper = styled.div`
  max-width: 100%;
  position: relative;
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
`;

const FormArea = styled(Field)`
  width: 100%;
  height: 70px;
  font-size: 18px;
  border-radius: 5px;
  padding-left: 5px;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid grey;
  margin-bottom: 20px;
  max-width: 850px;
  width: 850px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 135px;
  max-height: 135px;
  margin-bottom: 20px;
  justify-content: flex-end;
  align-items: right;
`;
const ImageUpload = styled.div`
  display: flex;
  max-width: 300px;
  min-height: 135px;
  max-height: 135px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid black;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ImageSliderUpload = styled.div`
  display: flex;
  min-width: 300px;
  max-width: 300px;
  min-height: 200px;
  max-height: 200px;
  margin-bottom: 20px;
  margin-right: 20px;
  border-radius: 5px;
  border: 1px solid black;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ImagePreview = styled(Img)`
  max-width: 300px;
  min-width: 300px;
  min-height: 135px;
  max-height: 135px;
  border-radius: 5px;
  border: 1px solid black;
`;

// const ImageWrapper = styled.div`
//   max-width: 100%;
//   position: relative;
//   min-width: 100%;
//   min-height: 100%;
//   max-height: 100%;
// `;

export default compose(
  withFormik({
    mapPropsToValues: (props) => {
      console.log(props);
      const hasEditorState =
        props.project && props.project.content.charAt(0) === "{";
      if (props.isEdit) {
        return {
          title: props.project.title,
          headerPicture: props.project.headerPicture,
          editorState: hasEditorState
            ? EditorState.createWithContent(
                convertFromRaw(JSON.parse(props.project.content))
              )
            : EditorState.createWithContent(
                ContentState.createFromText(props.project.content)
              ),
          published: props.project.published,
          summary: props.project.summary,
          finished: props.project.finished,
          imageSlider: props.project.imageSlider
            ? props.project.imageSlider
            : [],
        };
      } else {
        return {
          title: "",
          headerPicture: "",
          summary: "",
          imageSlider: [],
          editorState: createEditorStateWithText(""),
          published: false,
        };
      }
    },
    handleSubmit: (values, { props }) => {
      // you probably want to transform draftjs state to something else, but I'll leave that to you.
      const contentState = values.editorState.getCurrentContent();
      const content = JSON.stringify(convertToRaw(contentState));
      console.log(values);
      const submitValues = {
        title: values.title,
        headerTop: values.title,
        headerPicture: values.headerPicture,
        content,
        imageSlider: values.imageSlider,
        summary: values.summary,
        finished: values.finished,
        published: !!values.published,
        user: props.currentUser,
        isEnglish: props.isEnglish,
      };
      props.submitEndpoint.perform({}, submitValues).then((result) => {
        if (result.request.networkStatus === "success") {
          props.history.push("/cms/container/projects");
        }
      });
    },
    displayName: "MyForm",
  })
)(ProjectEditForm);
