import React, { PureComponent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import "./NewsPage.css";
import styled from "styled-components";
import MeernewsItem from "./MeerNews";
import { ContentPage } from "../components/ContentPage";
import { useApiData } from "react-api-data";
import { selectItem } from "../selectors/selectors";
import CustomGridLoader from "../Animations/CustomGridLoader";
import { css } from "@emotion/core";

const NewsItemPage = (props) => {
  const { newsItemId } = props.match.params;
  const intl = useIntl();
  const newsItems = useApiData("getNewsItems", {
    isEnglish: intl.locale === "en",
  });
  const newsItem =
    newsItems.request.networkStatus === "success"
      ? selectItem(newsItems.data, newsItemId)
      : undefined;
  if (!newsItem) return null;
  return (
    <Container>
      {newsItems.request.networkStatus === "success" && (
        <ContentPage
          type={"newsItem"}
          headerPicture={newsItem.headerPicture}
          headerTop={newsItem.headerTop}
          summary={newsItem.summary}
          content={newsItem.content}
          bodyPicture={newsItem.bodyPicture}
        />
      )}
      <MeernewsItem
        newsItems={newsItems && newsItems.data}
        currentNewsItem={newsItem._id}
      />
      <CustomGridLoader
        sizeUnit={"px"}
        size={50}
        margin={"15px"}
        css={override}
        color={"#007ec9"}
        secondColor={"#f71635"}
        loading={newsItems.request.networkStatus === "loading"}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  items-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const override = css`
  margin-top: 100px;
`;

export default NewsItemPage;
