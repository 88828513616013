import React, { Component } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from "reactstrap";
import logo from "../img/MayaLogo.svg";
import logoMobile from "../img/MayaLogo.svg";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import logOut from "../actions/admin/sign-out";
import "./Navigation.css";
import { withRouter, NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { getTotalAmountOfProducts } from "../reducers";
import ShoppingBasket from "./ShoppingBasket";
import { compose } from "redux";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components/macro";
import BurgerComponent from "./BurgerComponent";

class Navigation extends Component {
  state = {
    isOpen: false,
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname &&
      this.state.isOpen
    ) {
      this.setState({
        isOpen: false,
      });
    }
  }

  linkToDonation(e, isHome) {
    e.preventDefault();
    if (isHome) {
      window.location = "/join";
    } else {
      window.location = "/donation";
    }
  }

  logOut = (event) => {
    event.preventDefault();
    this.props.logOut();
  };

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { loggedIn, locale } = this.props;
    const { pathname } = this.props.location;
    const isMayaHome = window.location.pathname.includes("/mayahome");
    // console.log(this.props);
    if (this.props.location.pathname.includes("/cms")) {
      return null;
    } else {
      return (
        <Container>
          <InnerHeader>
            {/* {window.innerWidth > 1020 ( */}
            <NavLink to={`/${locale}/`}>
              <img src={logo} alt="" weign="40" height="40" />
            </NavLink>
            {window.innerWidth < 1020 && (
              <BurgerComponent
                setOpen={() => this.setState({ isOpen: !this.state.isOpen })}
                open={this.state.isOpen}
              />
            )}
            <StyledMenu open={this.state.isOpen}>
              <NavItem
                style={
                  pathname.includes("/projecten")
                    ? { borderBottom: "6px #007ec9 solid" }
                    : {}
                }
              >
                <StyledNavLink to={`/${locale}/projects`}>
                  <FormattedMessage id="projects" defaultMessage="Projecten" />
                </StyledNavLink>
              </NavItem>
              <NavItem
                style={
                  pathname.includes("/aboutus")
                    ? { borderBottom: "6px #007ec9 solid" }
                    : {}
                }
              >
                <StyledNavLink to={`/${locale}/aboutus`}>
                  <FormattedMessage id="aboutUs" defaultMessage="Over ons" />
                </StyledNavLink>
              </NavItem>
              <NavItem
                style={
                  pathname.includes("/news")
                    ? { borderBottom: "6px #007ec9 solid" }
                    : {}
                }
              >
                <StyledNavLink to={`/${locale}/news`}>
                  <FormattedMessage id="news" defaultMessage="Nieuws" />
                </StyledNavLink>
              </NavItem>
              <NavItem
                style={
                  pathname.includes("/contact")
                    ? { borderBottom: "6px #007ec9 solid" }
                    : {}
                }
              >
                <StyledNavLink to={`/${locale}/contact`}>
                  <FormattedMessage id="contact" defaultMessage="Contact" />
                </StyledNavLink>
              </NavItem>
              <NavItem
                style={
                  pathname.includes("/sponsor")
                    ? { borderBottom: "6px #007ec9 solid" }
                    : {}
                }
              >
                <StyledNavLink to={`/${locale}/sponsor`}>
                  <FormattedMessage
                    id="sponsor"
                    defaultMessage="Sponsorartikelen"
                  />
                </StyledNavLink>
              </NavItem>
              <NavItem
                style={
                  pathname.includes("/mayahome")
                    ? { borderBottom: "6px #007ec9 solid" }
                    : {}
                }
              >
                <StyledNavLink to={`/${locale}/mayahome`}>
                  <FormattedMessage id="mayaHome" defaultMessage="Maya Home" />
                </StyledNavLink>
              </NavItem>
              {loggedIn && (
                <NavItem className="pull-right-nav">
                  <a href="" onClick={this.logOut.bind(this)}>
                    Log out
                  </a>
                </NavItem>
              )}
              {!loggedIn && (
                <NavItem className="pull-right-nav">
                  <button
                    className="donate-button-nav"
                    onClick={() =>
                      this.props.history.push(
                        isMayaHome ? `/${locale}/join` : `/${locale}/donation`
                      )
                    }
                  >
                    {isMayaHome ? (
                      <FormattedMessage id="join" defaultMessage="Doe mee" />
                    ) : (
                      <FormattedMessage id="donate" defaultMessage="Doneren" />
                    )}
                  </button>
                </NavItem>
              )}
              <NavItem>
                <StyledNavLink
                  style={{ color: "#3b89ca" }}
                  to={`/${locale === "en" ? "nl" : "en"}/`}
                  onClick={() =>
                    this.props.setLocale(locale === "en" ? "nl" : "en")
                  }
                >
                  <p style={{ color: "#3b89ca" }}>
                    {locale === "en" ? "Nederlands" : "English"}
                  </p>
                </StyledNavLink>
              </NavItem>
              {this.props.products > 0 && (
                <NavItem style={{ paddingBottom: "10px" }}>
                  <StyledNavLink to="/checkout">
                    <ShoppingBasket amount={this.props.products} />
                  </StyledNavLink>
                </NavItem>
              )}
            </StyledMenu>
          </InnerHeader>
        </Container>
      );
    }
  }
}

const Container = styled.div`
  background-color: white;
  opacity: 1;
  height: 84px;
  z-index: 5;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.24);
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  font-family: "Nunito Sans";
  font-size: 16px;
  height: 65px;
  line-height: 35px !important;
  align-content: center;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  margin-top: 30px;
  color: #333333 !important;
`;

const InnerHeader = styled.div`
  padding: 1rem 3.5rem;
  display: flex;
  height: 100%;
  background-color: white;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1300px;
  margin: auto;
`;

export const StyledMenu = styled.nav`
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  background: white;
  text-align: center;
  padding: 2rem;
  position: absolute;
  top: 70px;
  left: 0;
  display: flex;
  padding-top: 205px;
  padding-bottom: 405px;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  transition: transform 0.3s ease-in-out;

  @media (min-width: 1080px) {
    display: flex;
    transform: none;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    width: 1200px;
    top: 0px;
    float: right;
    left: auto;
    padding: 0;
    right: 0;
    align-items: center;
    height: 100%;
  }
`;

const mapStateToProps = (state) => ({
  loggedIn: !!state.currentUser,
  products: getTotalAmountOfProducts(state),
});

export default compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps, { push, logOut })
)(Navigation);
