import request from "superagent";

export default class API {
  constructor(host, options = {}) {
    this.host = host || "https://maya-api.onrender.com";
    this.options = { ...this.defaultOptions, ...options };
  }
  logOut() {
    this.removeToken();
  }

  get(path) {
    return request.get(this.createUrl(path)).set(this.headers());
  }

  post(path, data = {}) {
    return request
      .post(this.createUrl(path))
      .set(this.headers())
      .send(data);
  }

  put(path, data = {}) {
    return request
      .put(this.createUrl(path))
      .set(this.headers())
      .send(data);
  }

  patch(path, data = {}) {
    return request
      .patch(this.createUrl(path))
      .set(this.headers())
      .send(data);
  }

  delete(path) {
    return request.delete(this.createUrl(path)).set(this.headers());
  }

  headers() {
    let headers = {
      Accept: "application/json",
    };

    if (this.isAuthenticated()) {
      headers.Authorization = `Bearer ${this.getToken()}`;
    }

    return headers;
  }

  isAuthenticated() {
    return !!this.getToken();
  }

  signOut() {
    localStorage.removeItem(this.options.tokenStorageKey);
  }

  // Create a full URL to our API, including the host and path
  createUrl(path) {
    return [this.host, path].join("");
  }

  getToken() {
    return localStorage.getItem(this.options.tokenStorageKey);
  }

  storeToken(token) {
    localStorage.setItem(this.options.tokenStorageKey, token);
  }
  removeToken() {
    localStorage.removeItem(this.options.tokenStorageKey);
  }
}
