import React, { PureComponent } from "react";
import styled from "styled-components";
import Appear from "./Appear";
import { convertFromRaw, EditorState, Editor } from "draft-js";
import { FormattedMessage } from "react-intl";

export const ContentPage = ({
  headerPicture,
  headerTop,
  summary,
  bodyPicture,
  content,
  type,
  isEnglish,
}) => {
  const hasEditorState = content.charAt(0) === "{";
  const editorState =
    hasEditorState &&
    EditorState.createWithContent(convertFromRaw(JSON.parse(content)));
  return (
    <Container>
      <CoverNewsItem style={{ backgroundImage: `url(${headerPicture})` }}>
        {(type === "project" || type === "aboutUs") && (
          <CoverText>{headerTop}</CoverText>
        )}
      </CoverNewsItem>
      <Body>
        {type === "newsItem" && (
          <div>
            <NewsItemHeader>{headerTop}</NewsItemHeader>
            <Summary>{summary}</Summary>
          </div>
        )}{" "}
        {hasEditorState ? (
          <Editor editorState={editorState} readOnly={true} />
        ) : (
          <p style={{ marginBottom: "15px" }}>{content}</p>
        )}
        {bodyPicture && <BodyImage src={bodyPicture} alt="bodyPicture" />}
        {type === "aboutUs" && (
          <div>
            {!isEnglish && (
              <a href="https://drive.google.com/file/d/0B_4y61TlXQRbR2p0d19HOTR2ZXIzT2ZueWVPMnRlYktaU1Q0/view?usp=sharing">
                Bekijk hier onze sponsormogelijkheden.
              </a>
            )}
            <br />
            <br />
            <a
              href="https://www.mayauniverseacademy.org"
              style={{ fontWeight: 900 }}
            >
              Maya Universe Academy Nepal
            </a>
            <br />
            <FormattedMessage id="aboutUsSub" />
            Bezoek de officiële website van Maya Universe Academy Nepal voor
            alle informatie over deze sociale beweging en inspirerende
            scholengemeenschap.
          </div>
        )}
      </Body>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  items-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CoverNewsItem = styled.div`
     width: 1100px;
     height: 450px;
     display: flex;
     align-items: center;
     justify-content: center;
     background-size: cover;
     object-fit: contain;
     background-repeat: no-repeat;
     @media only screen and (min-width: 275px) and (max-width: 480px) {
         width: 100%   
         object-fit: cover;
         height: 226px;
         background-size: cover;
         background-repeat: no-repeat;
     }
     
`;

const CoverText = styled.p`
  font-family: "Nunito Sans";
  font-size: 56px;
  font-weight: 600;
  line-height: 1.07;
  letter-spacing: 0;
  text-align: center;
  color: #ffffff;
  text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    font-family: "Nunito Sans";
    font-size: 24px;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: 0;
    text-align: center;
    color: #ffffff;
  }
`;

const Body = styled(Appear)`
      width: 1100px;
      margin-top: 20px;
      display: flex;
      items-align: left
      flex-direction: column;
      white-space: pre-line;
      font-family: 'Nunito Sans';
      font-size: 18px;
      letter-spacing: 0;
      text-align: center;
      color: #333333;
      text-align: left;
       @media only screen and (min-width: 275px) and (max-width: 480px) {
        width: 90%;
     }
`;

const NewsItemHeader = styled.p`
  margin-bottom: 25px;
  font-family: "Nunito Sans";
  font-size: 23px;
  font-weight: 600;
  color: #007ec9;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    font-size: 18px;
  }
`;

const Summary = styled.p`
  font-family: "Nunito Sans Extra-Light Italic";
  font-style: italic;
  font-weight: 300;
  font-color: "black";
`;

const BodyImage = styled.img`
    width: 100%;
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    @media only screen and (min-width: 275px) and (max-width: 480px) {
         width: 100%   
         object-fit: cover;
         height: 226px;
         background-size: cover;
         background-repeat: no-repeat;
     }
`;
