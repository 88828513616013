import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { replace, push } from "react-router-redux";
import TextField from "@material-ui/core/TextField";
import signIn from "../actions/admin/sign-in";
import logo from "../img/MayaLogo.svg";
import styled from "styled-components";
import { white } from "../styles/theme";

const textFieldStyle = {
  fontSize: "16px",
};

const buttonStyle = {
  float: "right",
  width: "100%",
  height: "40px",
  color: "white",
  background: "#f58320",
};

export class SignIn extends PureComponent {
  static propTypes = {
    push: PropTypes.func.isRequired,
    signIn: PropTypes.func.isRequired,
    signedIn: PropTypes.bool,
  };

  state = {
    email: "",
    password: "",
  };

  componentDidMount() {
    const { history, signedIn, push } = this.props;
    if (signedIn) {
      history.push("/cms");
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { history, signedIn } = this.props;
    if (!prevProps.signedIn && signedIn) {
      history.push("/cms");
    }
  }

  submitForm(event) {
    event.preventDefault();
    const user = {
      email: this.state.email,
      password: this.state.password,
    };
    this.props.signIn(user);
  }

  render() {
    console.log(this.props);
    return (
      <Container>
        <Paper>
          <img src={logo} alt="" weign="40" height="40" />

          <StyledForm onSubmit={this.submitForm.bind(this)}>
            <StyledTextField
              ref="email"
              type="email"
              label="Email address"
              style={textFieldStyle}
              onInput={(e) => this.setState({ email: e.target.value })}
            />

            <StyledTextField
              ref="password"
              type="password"
              label="Password"
              onInput={(e) => this.setState({ password: e.target.value })}
            />
          </StyledForm>
          {this.props.currentUser && this.props.currentUser.error && (
            <Error>Verkeerd wachtwoord of username</Error>
          )}
          <Button
            style={buttonStyle}
            onClick={this.submitForm.bind(this)}
            label="Sign in"
            primary={true}
          >
            Log in
          </Button>
        </Paper>
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100vw;
  height: 100vw;
  display: flex;
  background-color: #fef4ea;
`;

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  margin-bottom: 20px;
  font-size: 16px;
`;

const Error = styled.div`
  width: 100%;
  color: red;
`;

const Paper = styled.div`
  display: flex;
  margin: auto;
  padding: 24px;
  flex-direction: column;
  margin-top: 100px;
  height: 250px;
  width: 400px;
  border-radius: 3px;
  background: white;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 25%);
  justify-content: space-between;
`;

const Button = styled.button`
  margin-top: 0;
  width: 105px;
  height: 35px;
  border-radius: 3px;
  background-color: #f58320;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  color: #ffffff;
  position: relative;
  border-width: 0px;
`;

const mapStateToProps = ({ currentUser }) => ({
  signedIn: !!currentUser && !!currentUser._id,
  currentUser,
});

export default connect(mapStateToProps, { signIn, replace, push })(SignIn);
