
/** @jsx jsx */
import * as React from "react";
import { keyframes, css, jsx } from "@emotion/core";

const LOADING = "loading";
const COLOR = "color";
const CSS = "css";
const SIZE = "size";
const SIZE_UNIT = "sizeUnit";
const MARGIN = "margin";

const commonValues = {
    [LOADING]: true,
    [COLOR]: "#000000",
    [CSS]: {}
};

const sizeValues = (sizeValue) => ({
    [SIZE]: sizeValue,
    [SIZE_UNIT]: "px"
});

export const sizeDefaults = (sizeValue) => {
    return Object.assign({}, commonValues, sizeValues(sizeValue));
};


const sizeMarginDefaults = (sizeValue) => {
    return Object.assign({}, sizeDefaults(sizeValue), {
        [MARGIN]: "2px"
    });
};


const grid = keyframes`
  0% {transform: scale(1) rotate(-45deg); }
  50% {transform: scale(0.5) rotate(-45deg); opacity: 0.7; rotate(-45deg)}
  100% {transform: scale(1) rotate(-45deg); opacity: 1; rotate(-45deg)}
`;

const rotate = `
   rotate(-45deg)
`;

const random = (top) => Math.random() * top;

class Loader extends React.PureComponent {
    static defaultProps = sizeMarginDefaults(15);

    style = (rand) => {
        const {color, size, sizeUnit, margin, secondColor} = this.props;

        if (rand > 50) {
            return css`
      display: inline-block;
      background-color: ${color};
      width: ${`${size}${sizeUnit}`};
      height: ${`${size}${sizeUnit}`};
      margin: ${margin};
      border-radius: 100%;
      animation-fill-mode: "both";
      animation: ${grid} ${rand / 100 + 0.6}s ${rand / 100 - 0.2}s infinite ease;
    `;
        } else {
            return css`
      display: inline-block;
      background-color: ${secondColor};
      width: ${`${size}${sizeUnit}`};
      height: ${`${size}${sizeUnit}`};
      margin: ${margin};
      position: relative;
      top: 0;
      rotate(-45deg)
      animation-fill-mode: "both";
      animation: ${grid} ${rand / 100 + 0.6}s ${rand / 100 - 0.2}s infinite ease;
      :before,
        :after {
          content: "";
          background-color: ${secondColor};
          border-radius: 50%;
          width: ${`${size}${sizeUnit}`};
          height: ${`${size}${sizeUnit}`};
          position: absolute;
        }
        :before {
          top: ${`${-(size / 2)}${sizeUnit}`};
          left: 0;
        }
        :after {
          left: ${`${size / 2}${sizeUnit}`};
          top: 0;
        }
    `;
        }
    }

   wrapper = () => {
        const { size, sizeUnit, margin } = this.props;

        return css`
      width: ${`${parseFloat(size.toString()) * 3 + parseFloat(margin) * 6}${sizeUnit}`};
      font-size: 0;
    `;
    };

    render() {
        const { loading, css } = this.props;

        return loading ? (
            <div css={[this.wrapper(), css]}>
                <div css={[this.style(random(100)), rotate]} />
                <div css={[this.style(random(100)), rotate]} />
                <div css={[this.style(random(100)), rotate]} />
                <div css={[this.style(random(100)), rotate]} />
                <div css={[this.style(random(100)), rotate]} />
                <div css={[this.style(random(100)), rotate]} />
                <div css={[this.style(random(100)), rotate]} />
                <div css={[this.style(random(100)), rotate]} />
                <div css={[this.style(random(100)), rotate]} />
            </div>
        ) : null;
    }
}

export default Loader;