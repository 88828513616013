import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import styled from "styled-components";
import { withApiData } from "react-api-data";

import EditForm from "./EditForm";

const NewsItemCreate = (props) => {
  const { loggedIn } = props;

  if (loggedIn) {
    return (
      <TabContainer>
        <EditForm
          isEnglish={props.history.location.state.isEnglish}
          submitEndpoint={props.createNewsItem}
          currentUser={props.currentUser}
          history={props.history}
        />
      </TabContainer>
    );
  } else {
    return null;
  }
};

const TabContainer = styled.div`
  display: flex;
  width: 90%;
  margin: 100px 35px 35px 35px;
  padding: 25px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  flex-direction: column;
  background-color: white;
`;

const mapStateToProps = (state) => ({
  loggedIn: !!state.currentUser,
  currentUser: state.currentUser,
});
export default compose(
  withApiData({
    newsItems: "getNewsItems",
    createNewsItem: "createNewsItem",
  }),
  connect(mapStateToProps, null),
  lifecycle({
    componentDidMount() {
      if (!this.props.loggedIn) {
        this.props.history.push("/cms");
      }
    },
  })
)(NewsItemCreate);
