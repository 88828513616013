import React, { useState, useEffect } from "react";
import Navigation from "./components/Navigation";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import muiTheme from "./styles/theme";
import { ThemeProvider } from "@material-ui/core/styles";
import Routes from "./routes";
import ReactGA from "react-ga";
import { StickyContainer, Sticky } from "react-sticky";
import FooterComponent from "./components/FooterComponent";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";
import { EnglishMessages } from "./intl8";
import en from "./lang/en.json";
import nl from "./lang/nl.json";

const App = (props) => {
  ReactGA.initialize("UA-148364764-1");
  const isCms = window.location.pathname.includes("/cms");
  const isMayaHome = window.location.pathname.includes("/mayahome");
  ReactGA.pageview(window.location.pathname + window.location.search);
  const [locale, setLocale] = useState("nl");
  const messages = { en, nl };
  // useEffect(() => {
  //   // Update the document title using the browser API
  //   if (props.location.pathname === "/") {
  //     props.history.push("/nl/");
  //   }
  //   if (props.location.pathname.includes("/en/") && locale !== "en") {
  //     setLocale("en");
  //   }
  //   if (props.location.pathname.includes("/nl/") && locale !== "nl") {
  //     setLocale("en");
  //   }
  //   console.log(locale);
  // });
  return (
    <div className="App">
      <ThemeProvider theme={muiTheme}>
        <Routes locale={locale} />
      </ThemeProvider>
    </div>
  );
};

export const JoinHomeNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding-top: 3px;
  padding-bottom: 3px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.25);
  background-color: white;
  font-family: "Nunito Sans";
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  color: #333333;
  z-index: 15;
`;

export const JoinHomeAction = styled.div`
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  flex-direction: row;
  opacity: ${(props) => (props.disabled ? "0.7" : "1")};
  width: 200px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 70px;
  background-color: #f58320;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 40px;
  cursor: pointer;
  color: white;
  letter-spacing: 0.2px;
  text-align: center;
`;

export const ButtonConfirm = styled.div``;

export default withRouter(App);
