import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import styled from "styled-components";
import CmsTable from "./CmsTable";
import { withApiData, useApiData } from "react-api-data";
import { GridLoader } from "react-spinners";
import AddIcon from "@material-ui/icons/Add";
import { css } from "@emotion/core";
import Fab from "@material-ui/core/Fab";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
import LocaleSwitch from "./LocaleSwitch";

const columns = [
  { id: "name", label: "Nieuws titel", maxWidth: 170 },
  { id: "author", label: "Autheur", maxWidth: 100 },
  { id: "created", label: "Gemaakt op", maxWidth: 100 },
  { id: "publicated", label: "Gepubliceerd", maxWidth: 50 },
  { id: "edit", label: "edit", maxWidth: 50 },
  { id: "delete", label: "delete", maxWidth: 50 },
];

const getRows = (data) =>
  data.map((item) => ({
    id: item._id,
    name: item.title,
    author: item.user.length > 0 ? item.user[0].name : null,
    created: moment(item.startDate).format("DD-MM-YYYY"),
    publicated: item.published,
    edit: "newsitems",
    delete: "newsitems",
  }));

export const CustomFab = withStyles((theme) => ({
  root: {
    backgroundColor: "#007ec9",
    "&:hover": {
      backgroundColor: "#007ec9"[700],
    },
  },
}))(Fab);

export const CustomSmallFab = withStyles((theme) => ({
  root: {
    backgroundColor: "white",
    width: 30,
    height: 30,
    position: "absolute",
    top: -10,
    right: -10,
    "&:hover": {
      backgroundColor: "#007ec9"[700],
    },
  },
}))(Fab);

const NewsItemsListCms = (props) => {
  const { loggedIn } = props;
  const [language, setLanguage] = useState("dutch");
  const newsItems = useApiData("getCmsNewsItems", {
    isEnglish: language === "english" ? "true" : "false",
  });
  if (loggedIn) {
    return (
      <TabContainer>
        <LocaleSwitch language={language} setLanguage={setLanguage} />
        <Title>Nieuws</Title>
        {newsItems.request.networkStatus === "success" && (
          <Fragment>
            <CmsTable
              columns={columns}
              rows={getRows(newsItems.data)}
              history={props.history}
              language={language}
              deleteAction={props.deleteNewsItem}
            />
            <ButtonWrapper>
              <CustomFab
                color="primary"
                aria-label="add"
                onClick={() =>
                  props.history.push("/cms/container/newsitems/create", {
                    isEnglish: language === "english" ? "true" : "false",
                  })
                }
              >
                <AddIcon />
              </CustomFab>
            </ButtonWrapper>
          </Fragment>
        )}
        <GridLoader
          sizeUnit={"px"}
          size={50}
          margin={"15px"}
          css={override}
          color={"#007ec9"}
          loading={
            newsItems.request.networkStatus === "loading" ||
            props.deleteNewsItem.request.networkStatus === "loading"
          }
        />
      </TabContainer>
    );
  } else {
    return null;
  }
};

const TabContainer = styled.div`
  display: flex;
  width: 90%;
  max-width: 90%;
  margin: 100px 35px 35px 35px;
  padding: 25px;
  max-height: 90%;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  flex-direction: column;
  background-color: white;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  flex-direction: row-reverse;
`;

const Title = styled.h2`
  font-size: 23px;
  width: 100%;
  margin-bottom: 25px;
  margin-left: 16px;
`;

const override = css`
  margin: auto;
`;

const mapStateToProps = (state) => ({
  loggedIn: !!state.currentUser,
  currentUser: state.currentUser,
});
export default compose(
  withApiData({
    newsItems: "getCmsNewsItems",
    deleteNewsItem: "deleteNewsItem",
  }),
  connect(mapStateToProps, null),
  lifecycle({
    componentDidMount() {
      if (!this.props.loggedIn) {
        this.props.history.push("/cms");
      }
    },
  })
)(NewsItemsListCms);
