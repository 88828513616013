import React, { Fragment } from "react";
import styled from "styled-components";

const LocaleSwitch = ({ language, setLanguage }) => {
  return (
    <Container>
      <Language
        isActive={language === "dutch"}
        onClick={() => setLanguage("dutch")}
      >
        Nederlands
      </Language>{" "}
      /{" "}
      <Language
        isActive={language === "english"}
        onClick={() => setLanguage("english")}
      >
        Engels
      </Language>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 16px;
  margin-bottom: 25px;
  margin-left: 16px;
`;

const Language = styled.div`
  cursor: pointer;

  color: ${(props) => (props.isActive ? "blue" : "black")};
`;

export default LocaleSwitch;
