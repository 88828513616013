import { receiveProducts } from "../actions/products";
import { update } from "redux-updaters";
import { invalidateApiDataRequest, performApiRequest } from "react-api-data";
import API from "./client";

const defaultOptions = {
  tokenStorageKey: "coachApiJWT",
};
// "https://nameless-atoll-25790.herokuapp.com"
const endpoint = "https://maya-api.onrender.com";

const api = new API();

const headers = () => {
  let header = {
    Accept: "application/json",
    contentType: "application/json; charset=utf-8",
    Authorization: `Bearer ${api.getToken()}`,
  };
  return header;
};
//nameless-atoll-25790.herokuapp.com
export const endpointConfig = {
  getAddress: {
    url: `https://json.api-postcode.nl/:postcode/:number`,
    method: "GET",
    autoTrigger: false,
    setHeaders: (defaultHeaders) => ({
      ...defaultHeaders,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `9c47cf8c-1f5e-439c-a828-f68b33bcba5a`,
    }),
  },
  getProducts: {
    url: `${endpoint}/products`,
    method: "GET",
    afterSuccess: ({ resultData, dispatch }) =>
      dispatch(receiveProducts(resultData)),
  },
  setProducts: {
    url: `${endpoint}/products`,
    method: "POST",
    // afterSuccess: ({resultData, dispatch}) => dispatch(receiveProducts(resultData))
  },
  mollieOrder: {
    url: `${endpoint}/mollie-order`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch }) => {
      dispatch(update("payment.orderId", resultData.id));
      window.location.replace(resultData.checkoutUrl);
    },
  },
  mollieCustomer: {
    url: `${endpoint}/mollie-customer`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch }) => {
      dispatch(update("payment.customerId", resultData.id));
      dispatch(
        performApiRequest(
          "molliePayment",
          {},
          {
            customerId: resultData.id,
            amount: resultData.metadata,
          }
        )
      );
    },
  },
  mollieMandate: {
    url: `${endpoint}/mollie-mandate`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch, getState }) => {
      const state = getState();
      dispatch(
        performApiRequest(
          "mollieSubscription",
          {},
          {
            customerId: resultData.customerId,
            amount: state.payment.paymentAmount,
            method: resultData.method,
          }
        )
      );
    },
  },
  mollieSubscription: {
    url: `${endpoint}/mollie-subscription`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch }) => {
      dispatch(update("payment.subscriptionId", resultData.id));
    },
  },
  molliePayment: {
    url: `${endpoint}/mollie-payment`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch }) => {
      if (resultData.sequenceType === "first") {
        window.location.replace(resultData.checkoutUrl);
        dispatch(update("payment.paymentAmount", resultData.amount.value));
        dispatch(update("payment.paymentId", resultData.id));
      } else if (resultData.sequenceType === "oneoff") {
        window.location.replace(resultData.checkoutUrl);
        dispatch(update("payment.paymentId", resultData.id));
      }
    },
  },
  getOrder: {
    url: `${endpoint}/mollie-order/:id`,
    method: "GET",
  },
  getPayment: {
    url: `${endpoint}/mollie-payment/:id`,
    method: "GET",
  },
  getCustomer: {
    url: `${endpoint}/mollie-customer/:id`,
    method: "GET",
    afterSuccess: ({ resultData, dispatch, getState }) => {
      const state = getState();
      dispatch(
        performApiRequest(
          "subscribeMailchimp",
          {},
          {
            email: resultData.email,
          }
        )
      );
    },
  },
  subscribeMailchimp: {
    url: `${endpoint}/subscribe`,
    method: "POST",
  },
  unsubscribeMailchimp: {
    url: `${endpoint}/unsubscribe`,
    method: "POST",
  },
  getCustomerStop: {
    url: `${endpoint}/mollie-customer-stop`,
    method: "POST",
    autoTrigger: false,
  },
  getProjects: {
    url: `${endpoint}/projects/:isEnglish`,
    method: "GET",
  },
  getProject: {
    url: `${endpoint}/project/:id`,
    method: "GET",
  },
  getNewsItems: {
    url: `${endpoint}/newsItems/:isEnglish`,
    method: "GET",
  },
  deleteSubscription: {
    url: `${endpoint}/mollie-subscription`,
    method: "DELETE",
    autoTrigger: false,
  },
  createNewsItem: {
    url: `${endpoint}/newsItems`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch }) => {
      dispatch(invalidateApiDataRequest("getCmsNewsItems"), {});
    },
    setHeaders: headers,
  },
  getNewsItem: {
    url: `${endpoint}/newsItem/:id`,
    method: "GET",
  },
  editNewsItem: {
    url: `${endpoint}/newsItems/:id`,
    method: "PATCH",
    afterSuccess: ({ request, dispatch }) => {
      dispatch(invalidateApiDataRequest("getCmsNewsItems"), {});
      dispatch(invalidateApiDataRequest("getCmsNewsItem"), {
        id: request.params.id,
      });
    },
    setHeaders: headers,
  },
  deleteNewsItem: {
    url: `${endpoint}/newsItems/:id`,
    method: "DELETE",
    afterSuccess: ({ resultData, dispatch }) => {
      dispatch(invalidateApiDataRequest("getCmstNewsItems"), {});
    },
    setHeaders: headers,
  },
  createArticle: {
    url: `${endpoint}/products`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch }) => {
      dispatch(invalidateApiDataRequest("getCmsProducts"), {});
    },
    setHeaders: headers,
  },
  deleteProduct: {
    url: `${endpoint}/product/:id`,
    method: "DELETE",
    afterSuccess: ({ resultData, dispatch }) => {
      dispatch(invalidateApiDataRequest("getCmsProducts"), {});
    },
    setHeaders: headers,
  },
  editProduct: {
    url: `${endpoint}/product/:id`,
    method: "PATCH",
    afterSuccess: ({ request, dispatch }) => {
      dispatch(invalidateApiDataRequest("getCmsProducts"), {});
      dispatch(invalidateApiDataRequest("getCmsProduct"), {
        id: request.params.id,
      });
    },
    setHeaders: headers,
  },
  getProduct: {
    url: `${endpoint}/product/:id`,
    method: "GET",
  },
  deleteProject: {
    url: `${endpoint}/projects/:id`,
    method: "DELETE",
    afterSuccess: ({ resultData, dispatch }) => {
      dispatch(invalidateApiDataRequest("getCmsProjects"), {});
    },
    setHeaders: headers,
  },
  editProject: {
    url: `${endpoint}/projects/:id`,
    method: "PATCH",
    afterSuccess: ({ request, dispatch }) => {
      dispatch(invalidateApiDataRequest("getCmsProjects"), {});
      dispatch(invalidateApiDataRequest("getCmsProject"), {
        id: request.params.id,
      });
    },
    setHeaders: headers,
  },
  createProject: {
    url: `${endpoint}/projects`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch }) => {
      dispatch(invalidateApiDataRequest("getCmsProjects"), {});
    },
    setHeaders: headers,
  },
  getCmsProjects: {
    url: `${endpoint}/projectsCms/:isEnglish`,
    method: "GET",
  },
  getCmsNewsItems: {
    url: `${endpoint}/newsItemsCms/:isEnglish`,
    method: "GET",
  },
  getCmsProducts: {
    url: `${endpoint}/productsCms/:isEnglish`,
    method: "GET",
  },
  mayahomeCustomer: {
    url: `${endpoint}/mayahome/mollie-customer`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch }) => {
      dispatch(update("payment.customerId", resultData.id));
      dispatch(
        performApiRequest(
          "mayahomePayment",
          {},
          {
            customerId: resultData.id,
            amount: resultData.metadata,
          }
        )
      );
    },
  },
  mayahomeMandate: {
    url: `${endpoint}/mayahome/mollie-mandate`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch, getState }) => {
      const state = getState();
      dispatch(
        performApiRequest(
          "mayahomeSubscription",
          {},
          {
            customerId: resultData.customerId,
            amount: state.payment.paymentAmount,
            method: resultData.method,
          }
        )
      );
    },
  },
  mayahomeSubscription: {
    url: `${endpoint}/mayahome/mollie-subscription`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch }) => {
      dispatch(update("payment.subscriptionId", resultData.id));
    },
  },
  mayahomePayment: {
    url: `${endpoint}/mayahome/mollie-payment`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch }) => {
      if (resultData.sequenceType === "first") {
        window.location.replace(resultData.checkoutUrl);
        dispatch(update("payment.paymentAmount", resultData.amount.value));
        dispatch(update("payment.paymentId", resultData.id));
      } else if (resultData.sequenceType === "oneoff") {
        window.location.replace(resultData.checkoutUrl);
        dispatch(update("payment.paymentId", resultData.id));
      }
    },
  },
  mayahomeDonation: {
    url: `${endpoint}/mayahome/mollie-payment-once`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch }) => {
      if (resultData.sequenceType === "first") {
        window.location.replace(resultData.checkoutUrl);
        dispatch(update("payment.paymentAmount", resultData.amount.value));
        dispatch(update("payment.paymentId", resultData.id));
      } else if (resultData.sequenceType === "oneoff") {
        window.location.replace(resultData.checkoutUrl);
        dispatch(update("payment.paymentId", resultData.id));
      }
    },
  },
  mayahomeGetPayment: {
    url: `${endpoint}/mayahome/mollie-payment/:id`,
    method: "GET",
  },
  getMayaHome: {
    url: `${endpoint}/mayahome/:isEnglish`,
    method: "GET",
  },
  editMayaHome: {
    url: `${endpoint}/mayahome/:id`,
    method: "PATCH",
    afterSuccess: ({ request, dispatch }) => {
      dispatch(invalidateApiDataRequest("getMayaHome"), {});
    },
    setHeaders: headers,
  },
  getAboutUs: {
    url: `${endpoint}/aboutUs/:isEnglish`,
    method: "GET",
  },
  editAboutUs: {
    url: `${endpoint}/aboutUs/:id`,
    method: "PATCH",
    afterSuccess: ({ request, dispatch }) => {
      dispatch(invalidateApiDataRequest("getAboutUs"), {});
    },
    setHeaders: headers,
  },
  getDeStichting: {
    url: `${endpoint}/deStichting/:isEnglish`,
    method: "GET",
  },
  editDeStichting: {
    url: `${endpoint}/deStichting/:id`,
    method: "PATCH",
    afterSuccess: ({ request, dispatch }) => {
      dispatch(invalidateApiDataRequest("getDeStichting"), {});
    },
    setHeaders: headers,
  },
  publish: {
    url: "https://api.netlify.com/build_hooks/603ff70bc8a634007f7285d7",
    method: "POST",
  },
};
