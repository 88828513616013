import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import styled from "styled-components";
import { withApiData } from "react-api-data";
import ProjectEditForm from "./ProjectEditForm";

const ProjectEdit = (props) => {
  const { loggedIn } = props;
  if (loggedIn) {
    return (
      <TabContainer>
        {props.project.request.networkStatus === "success" && (
          <ProjectEditForm
            project={props.project.data}
            submitEndpoint={props.editProject}
            isEnglish={props.history.location.state.isEnglish}
            currentUser={props.currentUser}
            history={props.history}
            isEdit
          />
        )}
      </TabContainer>
    );
  } else {
    return null;
  }
};

const TabContainer = styled.div`
  display: flex;
  width: 90%;
  margin: 100px 35px 35px 35px;
  padding: 25px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  flex-direction: column;
  background-color: white;
`;

const mapStateToProps = (state) => ({
  loggedIn: !!state.currentUser,
  currentUser: state.currentUser,
});
export default compose(
  withApiData(
    {
      project: "getProject",
      editProject: "editProject",
    },
    (ownProps) => ({
      project: { id: ownProps.history.location.state.id },
      editProject: { id: ownProps.location.state.id },
    })
  ),
  connect(mapStateToProps, null),
  lifecycle({
    componentDidMount() {
      if (!this.props.loggedIn) {
        this.props.history.push("/cms");
      }
    },
  })
)(ProjectEdit);
