import React from "react";
import { FormattedMessage } from "react-intl";
import Facebook from "../img/facebook.png";
import LinkedIn from "../img/linkedIn.png";
import "../App.css";
import { connect } from "react-redux";

const FooterComponent = (props) =>
  !props.loggedIn && (
    <div class="footer" id="footer">
      <div class="grid-container">
        <div class="grid-info">
          <h2> Stichting Maya Universe Academy Netherlands</h2>
          <p>Haarlemmerweg 79 C, 1051KS Amsterdam Nederland</p>
          <a href="">RSIN – 856335733</a>
          <br />
          <a href="https://drive.google.com/file/d/0B_4y61TlXQRbdDJDd1Z3V1FfOFpvZ2Q3MDNneWtDZVdlY3JF/view?usp=sharing">
            <FormattedMessage
              id="remunerationPolicy"
              defaultMessage="Beloningsbeleid"
            />
          </a>
          <br />
          <a href="https://drive.google.com/file/d/0B_4y61TlXQRbemZ3UXVkRXJ4Qy14bENsSlR1akFqNWdneW1r/view?usp=sharing">
            ANBI
          </a>
          <br />
          <a href="https://drive.google.com/file/d/1LIcVCnAijyrh6b5qI15ddyszEhV_TZk9/view?usp=sharing">
            <FormattedMessage
              id="annualStatement"
              defaultMessage="Jaaropgave"
            />{" "}
            2019
          </a>
          <br />
          <a href="https://drive.google.com/file/d/0B_4y61TlXQRbR2p0d19HOTR2ZXIzT2ZueWVPMnRlYktaU1Q0/view?usp=sharing">
            <FormattedMessage
              id="sponsorLetter"
              defaultMessage="Sponsorbrief"
            />
          </a>
          <div style={{ display: "flex", flexDirection: "row", width: "50px" }}>
            <a href="https://www.facebook.com/muanetherlands/">
              <img
                src={Facebook}
                style={{
                  width: "35px",
                  height: "35px",
                  marginLeft: "0px",
                }}
              />
            </a>{" "}
            <a href="https://www.linkedin.com/company/maya-universe-academy-netherlands">
              <img
                src={LinkedIn}
                style={{ width: "35px", height: "35px", marginLeft: "10px" }}
              />
            </a>
          </div>
        </div>
        <div class="grid-contact">
          <h2>Rikke van der Veen</h2>
          <p>
            <FormattedMessage id="chairman" defaultMessage="Voorzitter" />
          </p>
          <p>rikke@mua-nl.org</p>
          <p>+31629139407</p>
        </div>
        <div class="grid-contact">
          <h2>Bas de Nijs</h2>
          <p>
            <FormattedMessage
              id="coFounderNepal"
              defaultMessage="Co-founder / Nepal coordinator"
            />
          </p>
          <p>bas@mua-nl.org</p>
          <p>+31681212053</p>
        </div>
        <div class="grid-contact">
          <h2>Hessel van Straalen</h2>
          <p>
            {" "}
            <FormattedMessage id="treasurer" defaultMessage="PenningMeester" />
          </p>
          <p></p>
          <p>+31623499903</p>
        </div>
        <div className="grid-contact">
          <h2>Manon Roest</h2>
          <p>
            <FormattedMessage id="secretary" defaultMessage="Secreatris" />
          </p>
          <p>manon@mual-nl.org</p>
        </div>
      </div>
    </div>
  );

const mapStateToProps = (state) => ({
  loggedIn: !!state.currentUser,
});

export default connect(mapStateToProps, null)(FooterComponent);
