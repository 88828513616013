import React, { PureComponent } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import "./ProjectCard.css";
import { compose } from "redux";
import { Img } from "react-image-loading";
import { withRouter } from "react-router-dom";

class ProjectCard extends PureComponent {
  constructor(props) {
    super(props);
    this.linkToDonation = this.linkToDonation.bind(this);
  }

  linkToProject = (projectId) => (event) =>
    this.props.history.push(`/${this.props.intl.locale}/${projectId}`);

  linkToDonation(e) {
    e.preventDefault();
    this.props.history.push(`/${this.props.intl.locale}/donation`);
  }

  render() {
    const { title, summary, finished, headerPicture, _id } = this.props;
    return (
      <div class="card-project">
        {finished ? <div className="finished"> VOLTOOID </div> : null}
        <Img src={headerPicture} alt="Avatar" />

        <div class="container-card">
          <h4 class="card-title">
            <b>{title}</b>
          </h4>

          <p class="card-summary">{summary}</p>
          <div className="linking-project">
            <button
              className="donate-button"
              onClick={this.linkToDonation.bind(this)}
            >
              <FormattedMessage id="donate" defaultMessage="Doneren" />{" "}
              <i className="arrow-project-right"></i>
            </button>
          </div>
          <div className="leesmeer" onClick={this.linkToProject(_id)}>
            <FormattedMessage id="readMore" defaultMessage="Lees meer" />{" "}
            <i className="arrow-leesmeer-right"></i>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withRouter, injectIntl)(ProjectCard);
