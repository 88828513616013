import handleViewPort from 'react-in-viewport';
import styled from 'styled-components';
import React, { FunctionComponent } from 'react';

const AppearWrapper = styled.section`
    transform: translateY(${({ enterCount }) => enterCount >= 1 ? '0' : '30px'});
    opacity: ${({ enterCount }) => enterCount >= 1 ? 1 : 0};
    transition: opacity 750ms, transform 750ms;
`;

// need to forward ref
const Appear = (props) => {
    return(
        <AppearWrapper ref={props.innerRef}{...props} />
    )
};


export default handleViewPort(
        Appear,
        {},
        { disconnectOnLeave: true }
);