export default [
            {
                name: 'hf-logo',
            },
            {
                name: 'Jointhepipe',
            },
            {
                name: 'Pure-Sportswear',
            },
            {
                name: 'logovandebron',
            },
            {
                name: 'waka-waka-logo-',
            },
            {
                name: 'sungevity-logo',
            },
            {
                name: 'wilde-ganzen',
            },
            {
                name: 'haella',
            },
        ]