import React, { Fragment } from "react";
import { compose, lifecycle } from "recompose";
import styled from "styled-components";
import { Form, Field, withFormik, FieldArray } from "formik";

import { Img } from "react-image-loading";
import AddIcon from "@material-ui/icons/Add";
import { ButtonConfirm } from "../sponsorartikelen/CheckoutForm";
import Icon from "@material-ui/core/Icon";
import { CustomFab, CustomSmallFab } from "./NewsItemsListCms";
import CloseIcon from "@material-ui/icons/Close";
import CustomEditor from "./CustomEditor";
import {
  CompositeDecorator,
  ContentState,
  convertFromRaw,
  convertToRaw,
  EditorState,
} from "draft-js";
import { Link, linkStrategy } from "./LinkPlugin";

const MayaHomeEditForm = (props) => {
  const onUploadThumbPicture = (setFieldValue, fieldValue) => {
    /*eslint-disable */
    cloudinary.openUploadWidget(
      {
        cloud_name: "djhc9jhiq",
        upload_preset: "FRONT_HEADER",
        tags: ["maya"],
      },
      /*eslint-enable */
      function(error, result) {
        if (error) {
          console.log(error);
        } else {
          if (result.info.url) {
          return setFieldValue(fieldValue, result.info.url);
          }
        }
      }
    );
  };
  const clearValue = (setFieldValue, fieldValue) =>
    setFieldValue(fieldValue, "");
  const handleToggle = (fieldValue) => {
    props.setFieldValue(fieldValue, !props.values.hasSizes);
  };
  return (
    <Fragment>
      <CreateNewsForm>
        <Label>Titel</Label>
        <FormInput type="text" name="title" />
        <Label>Header foto</Label>
        <ImageUpload>
          {!props.values.headerPhoto.length > 0 ? (
            <CustomFab
              color="primary"
              aria-label="add"
              onClick={() =>
                onUploadThumbPicture(props.setFieldValue, "headerPhoto")
              }
            >
              <AddIcon />
            </CustomFab>
          ) : (
            <ImageWrapper>
              <CustomSmallFab
                onClick={() => clearValue(props.setFieldValue, "headerPhoto")}
              >
                <CloseIcon />
              </CustomSmallFab>
              <ImagePreview src={props.values.headerPhoto}></ImagePreview>
            </ImageWrapper>
          )}
        </ImageUpload>
        <Label>intro text</Label>
        {props.values.editorState && (
          <CustomEditor
            editorState={props.values.editorState}
            onChange={props.setFieldValue}
            onBlur={props.handleBlur}
          />
        )}
        <Label style={{ marginBottom: "10px" }}>Experience text</Label>
        <FormArea
          type="text"
          style={{ width: "100%" }}
          name={`experienceText`}
          component="textarea"
        />
        <Label style={{ marginBottom: "10px" }}>Experience tabel</Label>
        <FieldArray
          name="experienceTable"
          render={(arrayHelpers) => (
            <DescriptionsList>
              {props.values.experienceTable.map((item, index) => (
                <div style={{ borderBottom: "1px solid black" }}>
                  <SwitchItem>
                    <Label style={{ marginLeft: "5px", width: "25%" }}>
                      Titel
                    </Label>
                    <Label style={{ marginLeft: "5px", width: "10%" }}>
                      prijs
                    </Label>
                    <Label style={{ marginLeft: "5px", width: "40%" }}>
                      subtitel
                    </Label>
                  </SwitchItem>
                  <DescriptionItem>
                    <FormInput
                      style={{ marginLeft: "5px", width: "25%" }}
                      name={`experienceTable.${index}.title`}
                    />
                    <FormInput
                      type="number"
                      style={{ marginLeft: "5px", width: "10%" }}
                      name={`experienceTable.${index}.price`}
                    />
                    <FormInput
                      style={{ marginLeft: "5px", width: "40%" }}
                      name={`experienceTable.${index}.subTitle`}
                    />
                  </DescriptionItem>
                  <FieldArray
                    name={`experienceTable.${index}.bulletPoints`}
                    render={(arrayHelpers2) => (
                      <>
                        {item &&
                          item.bulletPoints &&
                          item.bulletPoints.map((description, i) => (
                            <DescriptionItem>
                              &#10004;{" "}
                              <FormInput
                                style={{ marginLeft: "5px" }}
                                name={`experienceTable.${index}.bulletPoints.${i}`}
                              />
                              <Icon
                                onClick={() => arrayHelpers2.remove(i)}
                                style={{ marginLeft: "5px" }}
                              >
                                clear
                              </Icon>
                            </DescriptionItem>
                          ))}
                        <DescriptionItem>
                          <AddSquareButton
                            onClick={() => arrayHelpers2.push("")}
                          >
                            Voeg een beschrijving toe
                          </AddSquareButton>
                        </DescriptionItem>
                      </>
                    )}
                  />
                </div>
              ))}
              <DescriptionItem>
                <AddSquareButton onClick={() => arrayHelpers.push("")}>
                  Voeg een item toe
                </AddSquareButton>
              </DescriptionItem>
            </DescriptionsList>
          )}
        />
        <Label style={{ marginBottom: "10px" }}>Quotes</Label>
        <FieldArray
          name="experienceQuotes"
          render={(arrayHelpers) => (
            <DescriptionsList>
              {props.values.experienceQuotes.map((quote, index) => (
                <>
                  <SwitchItem>
                    <Label style={{ marginLeft: "5px", width: "25%" }}>
                      Naam
                    </Label>
                    <Label style={{ marginLeft: "5px", width: "25%" }}>
                      Land
                    </Label>
                    <Label style={{ marginLeft: "5px", width: "70%" }}>
                      Quote
                    </Label>
                  </SwitchItem>
                  <DescriptionItem>
                    <FormInput
                      style={{ marginLeft: "5px", width: "25%" }}
                      name={`experienceQuotes.${index}.name`}
                    />
                    <FormInput
                      style={{ marginLeft: "5px", width: "25%" }}
                      name={`experienceQuotes.${index}.country`}
                    />
                    <FormArea
                      type="text"
                      style={{ marginLeft: "5px", width: "70%" }}
                      name={`experienceQuotes.${index}.quote`}
                      component="textarea"
                    />
                    <Icon
                      onClick={() => arrayHelpers.remove(index)}
                      style={{ marginLeft: "5px" }}
                    >
                      clear
                    </Icon>
                  </DescriptionItem>
                </>
              ))}
              <DescriptionItem>
                <AddSquareButton onClick={() => arrayHelpers.push("")}>
                  Voeg een Quote toe
                </AddSquareButton>
              </DescriptionItem>
            </DescriptionsList>
          )}
        />
        <Label style={{ marginBottom: "10px" }}>Kosten text</Label>
        <FormArea
          type="text"
          style={{ width: "100%" }}
          name={`costsText`}
          component="textarea"
        />
        <Label style={{ marginBottom: "10px" }}>Kosten posten</Label>
        <FieldArray
          name="costs"
          render={(arrayHelpers) => (
            <DescriptionsList>
              {props.values.costs.map((quote, index) => (
                <>
                  <SwitchItem>
                    <Label style={{ marginLeft: "5px", width: "25%" }}>
                      Titel
                    </Label>
                    <Label style={{ marginLeft: "5px", width: "10%" }}>
                      Percentage
                    </Label>
                    <Label style={{ marginLeft: "5px", width: "10%" }}>
                      Kleur(hex)
                    </Label>
                  </SwitchItem>
                  <DescriptionItem>
                    <FormInput
                      style={{ marginLeft: "5px", width: "25%" }}
                      name={`costs.${index}.name`}
                    />
                    <FormInput
                      type="number"
                      style={{ marginLeft: "5px", width: "10%" }}
                      name={`costs.${index}.percentage`}
                    />
                    <FormInput
                      style={{ marginLeft: "5px", width: "10%" }}
                      name={`costs.${index}.color`}
                    />
                    <ColorPreview
                      backgroundColor={props.values.costs[index].color}
                    />
                    <Icon
                      onClick={() => arrayHelpers.remove(index)}
                      style={{ marginLeft: "5px" }}
                    >
                      clear
                    </Icon>
                  </DescriptionItem>
                  <FormArea
                    type="text"
                    style={{ width: "100%" }}
                    name={`costs.${index}.info`}
                    component="textarea"
                  />
                </>
              ))}
              <DescriptionItem>
                <AddSquareButton onClick={() => arrayHelpers.push("")}>
                  Voeg een kost toe
                </AddSquareButton>
              </DescriptionItem>
            </DescriptionsList>
          )}
        />
        <Label style={{ marginBottom: "10px" }}>Kids tekst</Label>
        <FormArea
          type="text"
          style={{ width: "100%" }}
          name={`kidsText`}
          component="textarea"
        />
        <ButtonWrapper>
          {!props.values.published && (
            <ConfirmButton
              onClick={() => props.handleSubmit()}
              disabled={props.isSubmitting}
            >
              Opslaan
            </ConfirmButton>
          )}
          <ConfirmButton
            onClick={(e) => {
              props.setFieldValue("published", true, false);
              props.handleSubmit(e);
            }}
            disabled={props.isSubmitting}
          >
            Opslaan en publiceren
          </ConfirmButton>
        </ButtonWrapper>
      </CreateNewsForm>
    </Fragment>
  );
};

const CreateNewsForm = styled(Form)`
    display: flex;
    width: 100%;
    padding: 25px;
    flex-direction: column;
    overflow: 'scroll'
    background-color: white;
`;

const ConfirmButton = styled(ButtonConfirm)`
    float: right
    margin-left: 10px;
`;

const AddSquareButton = styled(ButtonConfirm)`
  background-color: #007ec9;
  padding: 0;
`;

const Label = styled.p`
  font-size: 22px;
  font-family: "Arial";
  margin-bottom: 5px;
  background-color: #ebebeb;
`;

const FormArea = styled(Field)`
  height: 70px;
  font-size: 18px;
  border-radius: 5px;
  padding-left: 5px;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid grey;
  margin-bottom: 20px;
`;

const FormInput = styled(Field)`
  width: 100%;
  height: 30px;
  font-size: 18px;
  border-radius: 5px;
  padding-left: 5px
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid grey;
  margin-bottom: 10px;
`;

const DescriptionsList = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
`;

const DescriptionItem = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  font-size: 18px;
`;

export const SwitchItem = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 10px;
  font-size: 18px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 0;
    margin-bottom: 20px
    justify-content: flex-end;
    align-items: right;
`;

const ColorPreview = styled.div`
  display: flex;
  width: 50px;
  height: 30px;
  margin-left: 5px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: ${(props) => props.backgroundColor};
  padding: 0;
`;
const ImageUpload = styled.div`
  display: flex;
  min-width: 900px;
  max-width: 900px;
  min-height: 300px;
  max-height: 300px;
  margin-bottom: 20px;
  margin-right: 20px;
  border-radius: 5px;
  border: 1px solid black;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ImagePreview = styled(Img)`
  max-width: 900px;
  min-width: 900px;
  min-height: 300px;
  max-height: 300px;
  border-radius: 5px;
  border: 1px solid black;
`;

const ImageWrapper = styled.div`
  max-width: 100%;
  position: relative;
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
`;

const decorator = new CompositeDecorator([
  {
    strategy: linkStrategy,
    component: Link,
  },
]);

const convertToEditorState = (editorContent) => {
  const content = convertFromRaw(JSON.parse(editorContent));
  const editorState = EditorState.createWithContent(content, decorator);
  return editorState;
};

export default compose(
  withFormik({
    mapPropsToValues: (props) => {
      if (props.isEdit) {
        const hasEditorState =
          props.data &&
          props.data.introText &&
          props.data.introText.charAt(0) === "{";
        return {
          title: props.data.title,
          editorState: hasEditorState
            ? convertToEditorState(props.data.introText)
            : EditorState.createWithContent(
                ContentState.createFromText(
                  props.data.introText ? props.data.introText : ""
                )
              ),
          headerPhoto: props.data.headerPhoto ? props.data.headerPhoto : "",
          experienceText: props.data.experienceText,
          experienceTable: props.data.experienceTable
            ? props.data.experienceTable
            : [],
          experienceQuotes: props.data.experienceQuotes
            ? props.data.experienceQuotes
            : [],
          costsText: props.data.costsText,
          costs: props.data.costs ? props.data.costs : [],
          kidsText: props.data.kidsText ? props.data.kidsText : "",
        };
      } else {
        return {
          title: "",
          editorState: new EditorState.createEmpty(),
          headerPhoto: "",
          experienceText: "",
          experienceQuotes: [],
          costsText: "",
          costs: [],
        };
      }
    },
    handleSubmit: (values, { props }) => {
      const contentState = values.editorState.getCurrentContent();
      const content = JSON.stringify(convertToRaw(contentState));
      const submitValues = {
        title: values.title,
        introText: content,
        headerPhoto: values.headerPhoto,
        experienceText: values.experienceText,
        experienceTable: values.experienceTable,
        experienceQuotes: values.experienceQuotes,
        costsText: values.costsText,
        costs: values.costs,
        kidsText: values.kidsText,
        isEnglish: props.isEnglish,
      };
      props.submitEndpoint.perform({ id: props.id }, submitValues);
    },
    displayName: "MyForm",
  })
)(MayaHomeEditForm);
