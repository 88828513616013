
import API from '../../api/client'
import {
  APP_LOADING,
  APP_DONE_LOADING,
  LOAD_ERROR,
  LOAD_SUCCESS
} from '../loading'
export const PAYMENT = 'PAYMENT'

const api = new API()

export default (contributor, amount, project) => {
  return dispatch => {
    dispatch({ type: APP_LOADING })

  api.post('/mollie', contributor, amount, project)
    .then((res) => {
      dispatch({ type: APP_DONE_LOADING })
      dispatch({ type: LOAD_SUCCESS })

      dispatch({
        type: PAYMENT,
        payload: res

      })
        console.log(res)
        if (res.ok && window){
            window.location.href = res.text;}
    })

    .catch((error) => {
      dispatch({ type: APP_DONE_LOADING })
      dispatch({
        type: LOAD_ERROR,
        payload: error.message
      })
    })
  }
}
