import React from 'react'
import PropTypes from 'prop-types'
import trash from '../img/trash.svg';
import styled from "styled-components";
import {connect} from "react-redux";
import {addToCart, receiveProducts, removeProduct} from "../actions/products";

const Product = ({ price, quantity, title, image, id, removeProductFromCart, isCheckout }) => {
  return (quantity > 0) ? (
  <ProductItemContainer>
      <ProductText>{title} - 	&#8364;{price}{quantity ? ` x ${quantity}` : null}</ProductText>
      {isCheckout && <DeleteIcon src={trash} onClick={() => removeProductFromCart(id, quantity)} />}
  </ProductItemContainer> ): null;
}

const ProductItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between
`;

const DeleteIcon = styled.img`
  width: 18px;
  height: 18px;
  top: 10px;
  margin-bottom: -10px;
`
const ProductText = styled.div`
    font-family: 'Nunito Sans';
    font-size: 18px;
    display: flex;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    margin-left: 0;
    letter-spacing: 0.2px;
    text-align: left;
    color: #666666;
`;

const mapDispatchToProps = (dispatch, ownProps) => ({
  removeProductFromCart: (productId, quantity) => dispatch(removeProduct(productId, quantity))
})

export default connect(null, mapDispatchToProps)(Product)
