import React, { Fragment } from "react";
import { compose, lifecycle } from "recompose";
import styled from "styled-components";
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  withFormik,
  FieldArray,
} from "formik";

import { Img } from "react-image-loading";
import AddIcon from "@material-ui/icons/Add";
import { ButtonConfirm } from "../sponsorartikelen/CheckoutForm";
import Icon from "@material-ui/core/Icon";
import { CustomFab, CustomSmallFab } from "./NewsItemsListCms";
import CloseIcon from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const ArticleEditForm = (props) => {
  const onUploadPicture = (arrayHelpers) => {
    /*eslint-disable */
    cloudinary.openUploadWidget(
      {
        cloud_name: "djhc9jhiq",
        upload_preset: "FRONT_HEADER",
        tags: ["maya"],
      },
      /*eslint-enable */
      function(error, result) {
        if (error) {
          console.log(error);
        } else {
          if (result.info.url) {
          return arrayHelpers.push(result.info.url);
          }
        }
      }
    );
  };
  const onUploadThumbPicture = (setFieldValue, fieldValue) => {
    /*eslint-disable */
    cloudinary.openUploadWidget(
      {
        cloud_name: "djhc9jhiq",
        upload_preset: "FRONT_HEADER",
        tags: ["maya"],
      },
      /*eslint-enable */
      function(error, result) {
        if (error) {
          console.log(error);
        } else {
          if (result.info.url) {
          return setFieldValue(fieldValue, result.info.url);
          }
        }
      }
    );
  };
  const clearValue = (setFieldValue, fieldValue) =>
    setFieldValue(fieldValue, "");
  const handleToggle = (fieldValue) => {
    props.setFieldValue(fieldValue, !props.values.hasSizes);
  };
  return (
    <Fragment>
      <CreateNewsForm>
        <Label>Titel</Label>
        <FormInput type="text" name="title" />
        <Label>Kleur</Label>
        <FormInput type="text" name="color" />
        <Label>prijs(in euro's)</Label>
        <FormInput type="number" name="price" />
        <Label>Preview foto(400x600)</Label>
        <ImageUpload>
          {!props.values.thumbImage.length > 0 ? (
            <CustomFab
              color="primary"
              aria-label="add"
              onClick={() =>
                onUploadThumbPicture(props.setFieldValue, "thumbImage")
              }
            >
              <AddIcon />
            </CustomFab>
          ) : (
            <ImageWrapper>
              <CustomSmallFab
                onClick={() => clearValue(props.setFieldValue, "thumbImage")}
              >
                <CloseIcon />
              </CustomSmallFab>
              <ImagePreview src={props.values.thumbImage}></ImagePreview>
            </ImageWrapper>
          )}
        </ImageUpload>
        <Label style={{ marginBottom: "10px" }}>Product beschrijvingen</Label>
        <FieldArray
          name="descriptions"
          render={(arrayHelpers) => (
            <DescriptionsList>
              {props.values.descriptions.map((description, index) => (
                <DescriptionItem>
                  &#10004;{" "}
                  <FormInput
                    style={{ marginLeft: "5px" }}
                    name={`descriptions.${index}`}
                  />
                  <Icon
                    onClick={() => arrayHelpers.remove(index)}
                    style={{ marginLeft: "5px" }}
                  >
                    clear
                  </Icon>
                </DescriptionItem>
              ))}
              <DescriptionItem>
                <AddSquareButton onClick={() => arrayHelpers.push("")}>
                  Voeg een beschrijving toe
                </AddSquareButton>
              </DescriptionItem>
            </DescriptionsList>
          )}
        />
        <Label>Slider foto's(400x600)</Label>
        <FieldArray
          name="images"
          render={(arrayHelpers) => (
            <ImageSliderList>
              {props.values.images.map((image, index) => (
                <ImageUpload>
                  <ImageWrapper>
                    <CustomSmallFab onClick={() => arrayHelpers.remove(index)}>
                      <CloseIcon />
                    </CustomSmallFab>
                    <ImagePreview src={image}></ImagePreview>
                  </ImageWrapper>
                </ImageUpload>
              ))}
              <ImageUpload>
                <CustomFab
                  color="primary"
                  aria-label="add"
                  onClick={() => onUploadPicture(arrayHelpers)}
                >
                  <AddIcon />
                </CustomFab>
              </ImageUpload>
            </ImageSliderList>
          )}
        />
        <SwitchItem>
          <Field
            label="Dit product heeft maten"
            name="hasSizes"
            component={Switch}
            checked={props.values.hasSizes}
            onChange={() => handleToggle("hasSizes")}
          />
          <Label style={{ lineHeight: "40px" }}>Dit product heeft maten</Label>
        </SwitchItem>
        {props.values.hasSizes && (
          <>
            {props.values.sizes.length > 0 && (
              <SwitchItem>
                <Label style={{ marginLeft: "10px", width: 200 }}>Maat</Label>
                <Label style={{ marginLeft: "10px", width: 75 }}>Slug</Label>
                <Label style={{ marginLeft: "10px", width: 50 }}>stock</Label>
              </SwitchItem>
            )}
            <FieldArray
              name="sizes"
              render={(arrayHelpers) => (
                <DescriptionsList>
                  {props.values.sizes.map((image, index) => (
                    <DescriptionItem>
                      <FormInput
                        style={{
                          marginLeft: "10px",
                          width: 200,
                          marginBottom: "2",
                        }}
                        name={`sizes.${index}.size`}
                      />
                      <FormInput
                        style={{
                          marginLeft: "10px",
                          width: 75,
                          marginBottom: "2px",
                        }}
                        name={`sizes.${index}.slug`}
                      />
                      <FormInput
                        style={{
                          marginLeft: "10px",
                          width: 50,
                          marginBottom: "2px",
                        }}
                        name={`sizes.${index}.stock`}
                        type="number"
                      />
                      <Icon
                        onClick={() => arrayHelpers.remove(index)}
                        style={{ marginLeft: "5px" }}
                      >
                        clear
                      </Icon>
                    </DescriptionItem>
                  ))}
                  <DescriptionItem>
                    <AddSquareButton
                      onClick={() =>
                        arrayHelpers.push({ size: "", slug: "", stock: 0 })
                      }
                    >
                      Voeg een maat toe
                    </AddSquareButton>
                  </DescriptionItem>
                </DescriptionsList>
              )}
            />
          </>
        )}
        <ButtonWrapper>
          {!props.values.published && (
            <ConfirmButton
              onClick={() => props.handleSubmit()}
              disabled={props.isSubmitting}
            >
              Opslaan
            </ConfirmButton>
          )}
          <ConfirmButton
            onClick={(e) => {
              props.setFieldValue("published", true, false);
              props.handleSubmit(e);
            }}
            disabled={props.isSubmitting}
          >
            Opslaan en publiceren
          </ConfirmButton>
        </ButtonWrapper>
      </CreateNewsForm>
    </Fragment>
  );
};

const CreateNewsForm = styled(Form)`
    display: flex;
    width: 100%;
    padding: 25px;
    flex-direction: column;
    overflow: 'scroll'
    background-color: white;
`;

const ConfirmButton = styled(ButtonConfirm)`
    float: right
    margin-left: 10px;
`;

const AddSquareButton = styled(ButtonConfirm)`
  background-color: #007ec9;
  padding: 0;
`;

const Label = styled.p`
  font-size: 16px;
  font-family: "Arial";
  margin-bottom: 5px;
`;

const FormInput = styled(Field)`
  width: 100%;
  height: 30px;
  font-size: 18px;
  border-radius: 5px;
  padding-left: 5px;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid grey;
  margin-bottom: 10px;
`;

const DescriptionsList = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: flex-start;
`;
const ImageSliderList = styled.div`
  display: flex;
  width: 100%;
  min-height: 100px;
  flex-direction: row;
  justify-content: flex-start;
`;

const DescriptionItem = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  font-size: 18px;
`;

export const SwitchItem = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 10px;
  font-size: 18px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  justify-content: flex-end;
  align-items: right;
`;
const ImageUpload = styled.div`
  display: flex;
  min-width: 200px;
  max-width: 200px;
  min-height: 300px;
  max-height: 300px;
  margin-bottom: 20px;
  margin-right: 20px;
  border-radius: 5px;
  border: 1px solid black;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ImagePreview = styled(Img)`
  max-width: 200px;
  min-width: 200px;
  min-height: 300px;
  max-height: 300px;
  border-radius: 5px;
  border: 1px solid black;
`;

const ImageWrapper = styled.div`
  max-width: 100%;
  position: relative;
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
`;

export default compose(
  withFormik({
    mapPropsToValues: (props) => {
      if (props.isEdit) {
        return {
          title: props.article.title,
          color: props.article.color,
          descriptions: props.article.descriptions,
          images: props.article.images,
          thumbImage: props.article.thumbImage,
          price: props.article.price,
          published: props.article.published,
          hasSizes: props.article.sizes.length > 0,
          sizes: props.article.sizes,
        };
      } else {
        return {
          title: "",
          color: "",
          descriptions: [],
          images: [],
          thumbImage: "",
          price: null,
          hasSizes: false,
          sizes: [],
          published: false,
        };
      }
    },
    handleSubmit: (values, { props }) => {
      const submitSizes =
        values.hasSizes &&
        values.sizes.map((size) => {
          return { ...size, price: values.price };
        });
      const submitValues = {
        title: values.title,
        color: values.color,
        descriptions: values.descriptions,
        images: values.images,
        thumbImage: values.thumbImage,
        price: values.price,
        hasSizes: values.sizes.length > 0,
        sizes: submitSizes,
        published: values.published,
        isEnglish: props.isEnglish,
      };

      props.submitEndpoint.perform({}, submitValues).then((result) => {
        if (result.request.networkStatus === "success") {
          props.history.push("/cms/container/artikelen");
        }
      });
    },
    displayName: "MyForm",
  })
)(ArticleEditForm);
