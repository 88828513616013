import React, { useState } from "react";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { useApiData } from "react-api-data";
import { TabContainer } from "./NewsItemEdit";
import AboutUsForm from "./AboutUsForm";
import LocaleSwitch from "./LocaleSwitch";

const AboutUs = (props) => {
  const { loggedIn } = props;
  const [language, setLanguage] = useState("dutch");
  const aboutUs = useApiData("getAboutUs", {
    isEnglish: language === "english" ? "true" : "false",
  });
  const editAboutUs = useApiData("editAboutUs");
  if (loggedIn) {
    return (
      <TabContainer>
        <LocaleSwitch language={language} setLanguage={setLanguage} />
        {aboutUs.request.networkStatus === "success" &&
          language === "english" && (
            <AboutUsForm
              data={aboutUs.data}
              id={aboutUs.data && aboutUs.data._id}
              submitEndpoint={editAboutUs}
              currentUser={props.currentUser}
              history={props.history}
              isEdit
            />
          )}
        {aboutUs.request.networkStatus === "success" &&
          language === "dutch" && (
            <AboutUsForm
              data={aboutUs.data}
              id={aboutUs.data && aboutUs.data._id}
              submitEndpoint={editAboutUs}
              currentUser={props.currentUser}
              history={props.history}
              isEdit
            />
          )}
      </TabContainer>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => ({
  loggedIn: !!state.currentUser,
  currentUser: state.currentUser,
});

export default compose(
  connect(mapStateToProps, null),
  lifecycle({
    componentDidMount() {
      if (!this.props.loggedIn) {
        this.props.history.push("/cms");
      }
    },
  })
)(AboutUs);
