import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import React from "react";
import { makeStyles } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import styled from "styled-components";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "scroll",
    maxHeight: "70%",
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  tableCell: {
    fontSize: 14,
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#fafafa",
    },
  },
}))(TableRow);

const CmsTable = ({ columns, rows, history, deleteAction, language }) => {
  const classes = useStyles();
  return (
    <div className={classes.tableWrapper}>
      <Table stickyHeader className={classes.table}>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
                className={classes.tableCell}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            return (
              <StyledTableRow hover role="checkbox" tabIndex={-1} key={""}>
                {columns.map((column) => {
                  const value = row[column.id];
                  if (column.id === "edit") {
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={classes.tableCell}
                        onClick={() =>
                          history.push(`/cms/container/${value}/edit`, {
                            id: row.id,
                            isEnglish: language === "english",
                          })
                        }
                      >
                        <Icon>create</Icon>
                      </TableCell>
                    );
                  } else if (column.id === "delete") {
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={classes.tableCell}
                        onClick={() => {
                          if (
                            window.confirm(
                              "weet je zeker dat je dit item wil verwijderen"
                            )
                          ) {
                            deleteAction.perform({ id: row.id });
                          }
                        }}
                      >
                        <Icon>delete_sweep</Icon>
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.tableCell}
                    >
                      {column.id === "publicated" ? (
                        <PublicatedDot isPublicated={value} />
                      ) : (
                        value
                      )}
                    </TableCell>
                  );
                })}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

const PublicatedDot = styled.div`
    background-color: ${(props) => (props.isPublicated ? "green" : "red")}
    width: 10px;
    height: 10px;
    margin: auto;
    border-radius: 50%;
`;

export default CmsTable;
