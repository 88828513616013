import React, { useState } from "react";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { useApiData } from "react-api-data";
import { TabContainer } from "./NewsItemEdit";
import MayaHomeEditForm from "./MayaHomeEditForm";
import LocaleSwitch from "./LocaleSwitch";

const MayaHomeEdit = (props) => {
  const { loggedIn } = props;
  const [language, setLanguage] = useState("dutch");
  const mayaHome = useApiData("getMayaHome", {
    isEnglish: language === "english" ? "true" : "false",
  });

  const editMayaHome = useApiData("editMayaHome");

  if (loggedIn) {
    return (
      <TabContainer>
        <LocaleSwitch language={language} setLanguage={setLanguage} />
        {mayaHome.request.networkStatus === "success" &&
          language === "english" && (
            <MayaHomeEditForm
              data={mayaHome.data}
              id={mayaHome.data && mayaHome.data._id}
              submitEndpoint={editMayaHome}
              currentUser={props.currentUser}
              history={props.history}
              isEnglish
              isEdit
            />
          )}
        {mayaHome.request.networkStatus === "success" &&
          language === "dutch" && (
            <MayaHomeEditForm
              data={mayaHome.data}
              id={mayaHome.data && mayaHome.data._id}
              submitEndpoint={editMayaHome}
              currentUser={props.currentUser}
              history={props.history}
              isEdit
            />
          )}
      </TabContainer>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => ({
  loggedIn: !!state.currentUser,
  currentUser: state.currentUser,
});

export default compose(
  connect(mapStateToProps, null),
  lifecycle({
    componentDidMount() {
      if (!this.props.loggedIn) {
        this.props.history.push("/cms");
      }
    },
  })
)(MayaHomeEdit);
